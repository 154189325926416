import { TFormattedTask } from '@/pages/Dashboard/types'
import _first from 'lodash/first'
import { ReactNode } from 'react'

export function getWorkspaceTitle(task: TFormattedTask): string {
  const firstProject = _first(task.projects)
  const { name: workspaceName } = task.workspace

  return firstProject
    ? `${workspaceName} / ${firstProject.name}`
    : workspaceName
}

export function getWorkspaceName(task: TFormattedTask): ReactNode {
  const firstProject = _first(task.projects)
  const { name: workspaceName } = task.workspace

  if (!firstProject) return workspaceName

  return (
    <>
      <span>{workspaceName}</span>
      <span>{' / '}</span>
      <span>{firstProject.name}</span>
    </>
  )
}
