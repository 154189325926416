import { ApolloCache, FetchResult } from '@apollo/client'

import list from 'src/assets/images/list.svg'
import like from 'src/assets/images/like.svg'
import dislike from 'src/assets/images/dislike.svg'

import {
  FavouriteWorkspaceCreateMutation,
  FavouriteWorkspaceDeleteMutation,
  GetUserDataDocument,
} from 'src/__generated__/graphql'
import { TMenuListProps } from 'src/components/molecules/MenuList/types'
import {
  TAsanaWorkspace,
  TFavouriteWorkspace,
} from 'src/providers/UserProvider/types'
import config from 'src/config'

import * as S from './styles'

export const updateCacheAfterCreate = (
  cache: ApolloCache<any>,
  result: Omit<FetchResult<FavouriteWorkspaceCreateMutation>, 'context'>,
) => {
  const { data } = result
  const newWorkspaceData = data?.favoriteWorkspaceCreate
  const query: any = cache.readQuery({
    query: GetUserDataDocument,
  })

  const existing = query?.me?.favouriteWorkspaces
  const newFavouriteWorkspaces = existing ? [...existing] : []
  if (newWorkspaceData) {
    newFavouriteWorkspaces.push(newWorkspaceData)
  }

  cache.writeQuery({
    query: GetUserDataDocument,
    data: {
      ...query,
      me: query?.me
        ? {
            ...query.me,
            favouriteWorkspaces: newFavouriteWorkspaces,
          }
        : null,
    },
  })
}

export const updateCacheAfterDelete = (
  cache: ApolloCache<any>,
  result: Omit<FetchResult<FavouriteWorkspaceDeleteMutation>, 'context'>,
) => {
  const { data } = result
  const query: any = cache.readQuery({
    query: GetUserDataDocument,
  })
  const deletedId = data?.favoriteWorkspaceDelete?.id
  const existing = query?.me?.favouriteWorkspaces
  let newFavouriteWorkspaces = existing ? [...existing] : []
  newFavouriteWorkspaces = newFavouriteWorkspaces.filter(
    (workspace) => workspace.id !== deletedId,
  )

  cache.writeQuery({
    query: GetUserDataDocument,
    data: {
      ...query,
      me: query?.me
        ? {
            ...query?.me,
            favouriteWorkspaces: newFavouriteWorkspaces,
          }
        : null,
    },
  })
}

const createMenuList = (
  title: string,
  endIcon: string,
  workspaces: TAsanaWorkspace[],
  onEndIconClick: (id: string) => void,
): TMenuListProps => ({
  title: title,
  buttons: workspaces.map((workspace) => ({
    id: workspace.id,
    children: workspace.name,
    url: config.asanaWorkspaceLink + workspace.gid,
    startIcon: (
      <S.IconWrapper
        style={{ backgroundColor: workspace.userColor || undefined }}
      >
        <img src={list} alt='list' />
      </S.IconWrapper>
    ),
    endIcon: <img src={endIcon} alt='like' />,
    onEndIconClick: () => onEndIconClick(workspace.id),
  })),
})

export const formatLists = (
  allWorkspaces: TAsanaWorkspace[],
  favouriteWorkspaces: TFavouriteWorkspace[],
  addToFav: (id: string) => void,
  deleteFromFav: (id: string) => void,
): TMenuListProps[] => {
  const favouriteWorkspacesIds = favouriteWorkspaces.map(
    (workspace) => workspace.asanaWorkspaceId,
  )

  const favWorkspaces = allWorkspaces
    .filter((workspace) => favouriteWorkspacesIds.includes(workspace.id))
    .map((workspace) => {
      const favouriteWorkspace = favouriteWorkspaces.find(
        (fav) => fav.asanaWorkspaceId === workspace.id,
      )

      return {
        ...workspace,
        id: favouriteWorkspace?.id || workspace.id,
      }
    })

  const myWorkspaces = allWorkspaces.filter(
    (workspace) => !favouriteWorkspacesIds.includes(workspace.id),
  )

  const formattedFavouriteWorkspaces = createMenuList(
    'Ulubione',
    dislike,
    favWorkspaces,
    deleteFromFav,
  )
  const formattedMyWorkspaces = createMenuList(
    'Wszystkie',
    like,
    myWorkspaces,
    addToFav,
  )

  return [formattedFavouriteWorkspaces, formattedMyWorkspaces]
}
