import { ButtonBase, SxProps } from '@mui/material'
import { darken, lighten, styled } from '@mui/material/styles'

export const AllIconsWrapperHoverStyles: SxProps = {
  opacity: 1,
}

export const AllIconsWrapper = styled('div')(() => ({
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '4px',
  marginLeft: '8px',
  transition: 'opacity .14s',
  display: 'flex',
  flexShrink: 0,
  opacity: 0,
  marginRight: '8px',
  '@media (min-width: 1440px)': {
    marginRight: '12px',
  },
}))

export const SingleIconWrapper = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  boxSizing: 'border-box',
  color: darken(theme.mainTextColor, 0.2),
  backgroundColor:
    theme.palette.mode === 'dark'
      ? lighten(theme.mainBackground, 0.15)
      : darken(theme.mainBackground, 0.08),
  padding: 6,
  borderRadius: theme.borderRadius,

  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? lighten(theme.mainBackground, 0.2)
        : darken(theme.mainBackground, 0.12),
  },

  svg: {
    fontSize: '12px',
  },
}))
