import { ReactNode } from 'react'

import Loader from 'src/components/molecules/Loader'

import * as S from 'src/components/templates/Layout/styles'

interface ILayout {
  children: ReactNode
  isLoaderVisible?: boolean
}

const Layout = ({ children, isLoaderVisible }: ILayout) => {
  return (
    <S.LayoutWrapper>
      {isLoaderVisible && (
        <S.LoaderWrapper>
          <Loader />
        </S.LoaderWrapper>
      )}
      <S.Content>{children}</S.Content>
    </S.LayoutWrapper>
  )
}

export default Layout
