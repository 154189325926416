import { useRef } from 'react'
import useMessage from 'src/hooks/useMessage'
import { useMutation } from '@apollo/client'

import { formatDateIntoAsanaFormat } from 'src/utils/parseDateToDefaultFormat'

import type { TTask } from '@/pages/Dashboard/types'

import { SnackbarContext } from 'src/providers/SnackbarProvider'
import { ChangeDueOnDocument } from 'src/__generated__/graphql'

import { useTasksContext } from 'src/hooks/useTasksContext'

interface IUseUpdateTaskDueOnDateResult {
  updateTaskDueOnDate: (taskId: TTask['id'], newDueOn: Date | null) => void
}

export function useDueOnChange(): IUseUpdateTaskDueOnDateResult {
  const recoveryDueDates = useRef<Map<TTask['id'], TTask['due_on']>>(new Map())

  const { setSnackbarMessage } = useMessage(SnackbarContext)
  const { updateTaskLocally, setNumberOfUpdateMutationsInQueue } =
    useTasksContext()

  const [changeDueOn] = useMutation(ChangeDueOnDocument, {
    onError: (e) => {
      setSnackbarMessage('Nie udało sie zmienić daty', {
        isError: true,
      })
      throw e
    },
    onCompleted: (_, options) => {
      const updatedToDueDate: Date | null =
        options && options.variables && options.variables.dueOn

      setSnackbarMessage(
        updatedToDueDate !== null
          ? 'Udało się zmienić datę!'
          : 'Data wykonania została skasowana!',
      )
    },
  })

  const updateTaskDueOnDate: IUseUpdateTaskDueOnDateResult['updateTaskDueOnDate'] =
    function (taskId, newDueOnDate) {
      // Apply the change locally
      updateTaskLocally(taskId, (currentValue) => {
        recoveryDueDates.current.set(taskId, currentValue.due_on)

        return {
          due_on: formatDateIntoAsanaFormat(newDueOnDate),
        }
      })
      setNumberOfUpdateMutationsInQueue((val) => val + 1)

      // Apply the change to the api
      changeDueOn({
        variables: {
          id: taskId,
          dueOn: formatDateIntoAsanaFormat(newDueOnDate),
        },
      })
        .finally(() => {
          setNumberOfUpdateMutationsInQueue((val) => val - 1)
        })
        .catch((e) => {
          if (recoveryDueDates.current.has(taskId)) {
            updateTaskLocally(taskId, {
              due_on: recoveryDueDates.current.get(taskId),
            })

            recoveryDueDates.current.delete(taskId)
          }

          console.error(e)
        })
    }

  return {
    updateTaskDueOnDate,
  }
}
