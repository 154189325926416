import pl from 'date-fns/locale/pl'
import { alpha } from '@mui/material'
import { useCalendarPopupOpenerRef, useDatePickerState } from './hooks'
import { isDateBeforeToday } from 'src/utils/isDateBeforeToday'

import * as S from './styles'
import { CalendarIcon, LocalizationProvider } from '@mui/x-date-pickers'
import CloseIcon from '@mui/icons-material/Close'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ClickableIcons from '../ClickableIcons'

interface IProps {
  defaultValue: Date | null
  onChange: (value: Date | null) => void
}

const DatePicker = ({ defaultValue, onChange }: IProps) => {
  const hideClearButton: boolean = defaultValue === null

  const { calendarPopupOpenerRef, openCalendarPopup } =
    useCalendarPopupOpenerRef()

  const { format, value, clearDueDate, updateDueDate } = useDatePickerState({
    defaultValue,
    onChange,
  })

  return (
    <>
      <S.Wrapper hideClearButton={hideClearButton}>
        <S.InvisibleClickableArea
          onClick={openCalendarPopup}
          hideClearButton={hideClearButton}
        />

        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
          <S.DatePicker
            isRed={isDateBeforeToday(value)}
            defaultValue={defaultValue}
            format={format}
            onChange={updateDueDate}
            disableHighlightToday={false}
            slotProps={{
              desktopPaper: {
                sx: (theme) => ({
                  background:
                    theme.palette.mode === 'dark'
                      ? theme.mediumBackground
                      : theme.white,
                }),
              },
              openPickerButton: {
                ref: calendarPopupOpenerRef,
                sx: {
                  display: 'none',
                },
              },
              day: ({ day, selected }) => {
                /** Meaning dates before today, excluding currently selected date */
                const applyStylesForPastDates: boolean =
                  !selected && isDateBeforeToday(day)

                return {
                  sx: (theme) => ({
                    ...(applyStylesForPastDates && {
                      '&:not(&:hover)': {
                        opacity: 0.64,
                      },
                    }),

                    ...(!selected && {
                      '&:hover': {
                        background: alpha(theme.lightBlue, 0.14),
                      },
                      '&.MuiPickersDay-today': {
                        background: alpha(theme.lightBlue, 0.3),
                        '&:hover': {
                          background: alpha(theme.lightBlue, 0.44),
                        },
                      },
                    }),
                  }),
                }
              },
            }}
          />
        </LocalizationProvider>

        <ClickableIcons
          actions={[
            {
              icon: <CalendarIcon />,
              onClick: openCalendarPopup,
              tooltip: 'Wybierz datę wykonania',
            },
            {
              icon: <CloseIcon />,
              onClick: clearDueDate,
              tooltip: 'Usuń datę wykonania',
              onlyWhen: !hideClearButton,
            },
          ]}
        />
      </S.Wrapper>
    </>
  )
}

export default DatePicker
