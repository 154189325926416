import { useMemo } from 'react'
import _remove from 'lodash/remove'
import { useTasksSorter } from './hooks/useTasksSorter'
import { useUserContext } from 'src/hooks/useUserContext'
import { useTasksContext } from 'src/hooks/useTasksContext'

import { dates, getNumberOfTasks } from './utils'

import type { TGroupedTasks } from '../../types'

interface IUseTasksResult {
  /** Tasks categorized by their due date and ready to display to the user */
  tasksToRender: TGroupedTasks
  totalNumberOfTasks: number
}

export function useTasksCategorizedBasedOnDueDate(): IUseTasksResult {
  const tasksContext = useTasksContext()
  const { lastFetchDate } = useUserContext()

  const { sortTasks } = useTasksSorter()

  const tasksToRender = useMemo<IUseTasksResult['tasksToRender']>(() => {
    const tasks = tasksContext.tasks

    if (!tasks || !tasks.length) {
      return {
        outdated: [],
        forToday: [],
        forTomorrow: [],
        forMonday: [],
        upcomingDate: [],
        withoutDate: [],
      }
    }

    const tasksToRegroup = tasks.map(dates.addParsedDate)

    const withDate = _remove(tasksToRegroup, (task) => task.due_on)

    return {
      outdated: sortTasks(_remove(withDate, dates.checkIfOutdated)),
      forToday: sortTasks(_remove(withDate, dates.checkIfForToday)),
      forTomorrow: sortTasks(_remove(withDate, dates.checkIfForTomorrow)),
      forMonday: sortTasks(_remove(withDate, dates.checkIfForNextMonday)),
      upcomingDate: sortTasks(_remove(withDate, dates.checkIfUpcoming)),
      withoutDate: sortTasks(tasksToRegroup),
    }
  }, [tasksContext.tasks])

  const totalNumberOfTasks = useMemo<number>(() => {
    return getNumberOfTasks(tasksToRender)
  }, [tasksToRender, lastFetchDate])

  return {
    tasksToRender,
    totalNumberOfTasks,
  }
}
