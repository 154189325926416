import { PaletteMode } from '@mui/material'
import { ExtendedThemeOptions, TPalette } from './types'

export const lightModePalette: TPalette = {
  blue: '#15B7DF',
  lightBlue: '#30CAF0',
  darkBlue: '#04AED8',
  red: '#E63A52',
  teal: '#00BAE7',
  gray: '#E0E0E0FF',
  lightGray: '#FBFBFB',
  mediumGray: '#647380',
  superDarkBlue: '#0B0826',
  superDarkPurple: '#151735',
  white: '#FFFFFF',
  black: '#000000',
  green: '#3CA867',
  orange: '#FDA529',
  errorRed: '#FC6239',
  lazure: '#21B7DC',
  darkText: '#272C36',
  darkRed: '#590D0C',
  pink: '#FC8280',

  mainTextColor: '#000000',
  mainBackground: '#FFFFFF',
  lightBackground: '#fff',
  mediumBackground: '#F5F3F3',
  sidebarBackground: '#f9f8f8',
  mediumBackgroundShadow: '#E0DBD4',
  tableHover: '#F7F7F7',
  mainBorderColor: '#EDEAE9',

  buttonBoxShadowColor: 'rgba(21, 183, 223, 0.4)',
  iconButtonColor: '#6D6E6F',

  scrollbarBackground: '#f9f8f8',
  scrollbarTrack: '#A7A7A7',
}

export const darkModePalette: TPalette = {
  blue: '#15B7DF',
  lightBlue: '#30CAF0',
  darkBlue: '#04AED8',
  red: '#E63A52',
  teal: '#00BAE7',
  gray: '#E0E0E0FF',
  lightGray: '#FBFBFB',
  mediumGray: '#647380',
  superDarkBlue: '#FFFFFF',
  superDarkPurple: '#151735',
  white: '#FFFFFF',
  black: '#000000',
  green: '#3CA867',
  orange: '#FDA529',
  errorRed: '#FC6239',
  lazure: '#21B7DC',
  darkText: '#272C36',
  darkRed: '#590D0C',
  pink: '#FC8280',

  mainTextColor: '#FFFFFF',
  mainBackground: '#0B0A21',
  lightBackground: '#1C1B2E',
  mediumBackground: '#29283F',
  sidebarBackground: '#0B0A21',
  mediumBackgroundShadow: '#242338',
  tableHover: '#1C1B2E',
  mainBorderColor: '#242338',

  buttonBoxShadowColor: 'rgba(21, 183, 223, 0.4)',
  iconButtonColor: '#ffffff',

  scrollbarBackground: '#0B0A21',
  scrollbarTrack: '#1C1B2E',
}

export const variables = (
  mode: PaletteMode,
  palette: TPalette,
): ExtendedThemeOptions => ({
  ...palette,

  palette: {
    mode,
  },

  borderRadius: '4px',

  fonts: {
    h1: '60px',
    h2: '42px',
    h3: '36px',
    h4: '23px',
    button: '17px',
    large: '18px',
    medium: '15px',
    regular: '14px',
    small: '13px',
    superExtraSmall: '11px',
  },

  typography: {
    fontFamily: 'roboto, sans-serif',

    h4: {
      fontSize: '1rem',
      lineHeight: '1.3rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '0.834rem',
      lineHeight: '0.9rem',
      fontWeight: 400,
    },

    fontWeightBold: 600,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300,
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            border: 'transparent !important',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          '&.Mui-checked': {
            // Controls checked color for the touch ripple
            color: palette.blue,
          },
        },
        thumb: {
          backgroundColor: palette.gray,

          '.Mui-checked &': {
            // controls checked switch track
            backgroundColor: palette.blue,
          },
        },
        track: {
          backgroundColor: palette.gray,

          '.Mui-checked.Mui-checked + &': {
            // controls checked switch track
            backgroundColor: palette.blue,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '14px 12px',
          height: 'auto',
          minHeight: 'initial !important',
          backgroundColor: palette.mainBackground,
          borderRadius: '4px',
          color: palette.mainTextColor,
          lineHeight: 'normal',
          display: 'flex',
          alignItems: 'center',
          border: `1px solid ${palette.mainBorderColor}`,
        },

        icon: {
          color: palette.mediumGray,
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: palette.darkBlue,
          padding: 0,

          '&.Mui-checked': {
            color: palette.blue,
          },
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        list: {
          background:
            mode === 'dark' ? palette.mediumBackground : palette.white,
        },
      },
    },

    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          color: palette.white,
        },
      },
    },
  },
})
