import { AppBar as MaterialAppBar } from '@mui/material'
import { styled } from '@mui/material/styles'

export const AppBar = styled(MaterialAppBar)(({ theme }) => ({
  background: theme.mediumBackground,
  boxShadow: `0px 1px 0px ${theme.mediumBackgroundShadow}`,
  position: 'sticky',
  top: 0,
  zIndex: 10,

  '.MuiToolbar-root': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
    minHeight: 'unset',
    paddingRight: '12px',

    '.MuiAvatar-root': {
      height: 32,
      width: 32,
    },
  },
}))

export const LogoContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

export const ProfileContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
}))

export const SyncContainer = styled('div')(() => ({
  '@media (max-width: 450px)': {
    display: 'none',
  },
}))
