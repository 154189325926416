import { TableCell as MaterialTableCell } from '@mui/material'
import { styled } from '@mui/material/styles'

type TFieldType = 'WORKSPACE' | 'TASK_NAME' | 'DATE_PICKER' | 'PRIORITY_PICKER'

export default styled(MaterialTableCell, {
  shouldForwardProp: (prop: string) =>
    !(prop === 'fieldType' || prop === 'isColoredCell'),
})<{
  isColoredCell?: boolean
  fieldType: TFieldType
}>(({ theme, isColoredCell, fieldType }) => ({
  position: 'relative',
  maxWidth: 0,
  maxHeight: '39px',
  color: theme.mainTextColor,
  borderBottom: `1px solid ${theme.mainBorderColor}`,
  padding: 0,
  '@media (min-width: 1440px)': {
    paddingTop: '0.3px',
    paddingBottom: '0.3px',
  },

  ...(fieldType === 'WORKSPACE'
    ? {
        width: '25%',
        borderRight: `1px solid ${theme.mainBorderColor}`,
      }
    : {}),

  ...(isColoredCell
    ? {
        '& > span': {
          fontWeight: 'bold',
        },
        'span + span': {
          marginLeft: '6px',
        },
      }
    : {}),

  ...(fieldType === 'DATE_PICKER'
    ? {
        minWidth: 'max-content',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        width: '7%',
        fontWeight: 'bold',
        borderLeft: `1px solid ${theme.mainBorderColor}`,
        paddingLeft: '0 !important',
      }
    : {}),

  ...(fieldType === 'PRIORITY_PICKER'
    ? {
        minWidth: '120px',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        width: '9%',
        fontWeight: 'bold',
        borderLeft: `1px solid ${theme.mainBorderColor}`,

        paddingLeft: '0 !important',
        paddingRight: '0 !important',
      }
    : {}),

  '&:hover': {
    '&::before': {
      position: 'absolute',
      width: '100%',
      height: '1px',
      content: '""',
      top: theme.palette.mode === 'dark' ? 0 : -1,
      left: 0,
      backgroundColor: theme.mediumBackgroundShadow,
    },
    '&::after': {
      position: 'absolute',
      width: '100%',
      height: '1px',
      content: '""',
      bottom: theme.palette.mode === 'dark' ? 0 : -1,
      left: 0,
      backgroundColor: theme.mediumBackgroundShadow,
    },
    '&:not(&:first-of-type)': {
      borderLeft: `1px solid ${theme.mediumBackgroundShadow}`,
    },
    '&:not(&:last-of-type)': {
      borderRight: `1px solid ${theme.mediumBackgroundShadow}`,
    },
  },
}))
