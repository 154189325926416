import * as S from './styles'
import { PaletteMode, Typography } from '@mui/material'
import Divider from 'src/components/atoms/Divider/Divider'
import React, { useState } from 'react'
import TokenAskForm from './components/TokenAskForm'
import { useUserContext } from 'src/hooks/useUserContext'
import ThemeSelector from './components/ThemeSelector/ThemeSelector'
import { ThemedButton } from 'src/components/atoms/ThemedButton/ThemedButton'
import TextLink from 'src/components/atoms/TextLink'
import { useMutation } from '@apollo/client'
import {
  UpdateAsanaPatDocument,
  AsanaSettingSetValueDocument,
} from 'src/__generated__/graphql'
import useMessage from 'src/hooks/useMessage'
import { SnackbarContext } from 'src/providers/SnackbarProvider'
import { getStringWithCapitalLetter } from 'src/utils/getStringWithCapitalLetter'
import config from 'src/config'
import { useColorPaletteContext } from 'src/hooks/useColorPaletteContext'

type TErrorMessage = {
  message?: string
}

const BasicSettings = () => {
  const { user, settings } = useUserContext()
  const { changePaletteMode, paletteModeInUse } = useColorPaletteContext()

  const { setSnackbarMessage } = useMessage(SnackbarContext)

  const [token, setToken] = useState(user.asanaPat || '')
  const [selectedPaletteMode, setSelectedPaletteMode] =
    useState<PaletteMode>(paletteModeInUse)

  const [updateAsanaPat, { loading: isLoadingUpdatePat }] = useMutation(
    UpdateAsanaPatDocument,
    {
      refetchQueries: ['GetUserData'],
    },
  )
  const [asanaSettingSetValue, { loading: isLoadingSettingValue }] =
    useMutation(AsanaSettingSetValueDocument)

  const onTokenChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setToken(target.value)
  }

  const saveSettings = async () => {
    try {
      if (paletteModeInUse !== selectedPaletteMode) {
        changePaletteMode(selectedPaletteMode)

        if (settings.themeMode?.id) {
          await asanaSettingSetValue({
            variables: {
              id: settings.themeMode?.id,
              value: selectedPaletteMode,
            },
          })
        }
      }

      if (user.id !== null && token.length !== 0 && token !== user.asanaPat) {
        await updateAsanaPat({
          variables: {
            input: {
              id: user.id,
              asana_pat: token,
            },
          },
        }).catch((error) => {
          const errorField: string = 'input.asana_pat'
          const message = getStringWithCapitalLetter(
            error.graphQLErrors[0].extensions.validation[errorField][0],
          )
          throw new Error(message)
        })
      }

      setSnackbarMessage('Ustawienia zostały zaktualizowane!')
    } catch (error) {
      const errorObj = error as TErrorMessage
      const errorMessage = errorObj?.message

      setSnackbarMessage(
        errorMessage || 'Cos poszło nie tak... Spróbuj ponownie później',
        {
          isError: true,
        },
      )
    }
  }

  return (
    <S.Container>
      <Typography fontWeight={600} variant={'body1'} component={'h4'}>
        Ustawienia podstawowe
      </Typography>
      <Divider spacingTop={16} spacingBottom={32} />

      <S.ContentWrapper>
        <S.ItemWrapper>
          <TokenAskForm
            newTokenValue={token}
            onChangeNewTokenValue={onTokenChange}
          />
          <TextLink href={config.asanaTokenPDFLink} target={'_blank'}>
            Skąd wziąć access token
          </TextLink>
        </S.ItemWrapper>

        <S.ItemWrapper>
          <Typography fontWeight={600} variant={'body1'} component={'h6'}>
            Motyw
          </Typography>

          <ThemeSelector
            onThemeChange={setSelectedPaletteMode}
            theme={selectedPaletteMode}
          />
        </S.ItemWrapper>

        <S.ButtonContainer>
          <ThemedButton
            size={'medium'}
            label={'Zapisz'}
            onClick={saveSettings}
            isLoading={isLoadingUpdatePat || isLoadingSettingValue}
          />
        </S.ButtonContainer>
      </S.ContentWrapper>
    </S.Container>
  )
}

export default BasicSettings
