import React from 'react'

import * as S from './styles'
import { Typography } from '@mui/material'

interface ITokenAskForm {
  newTokenValue: string
  onChangeNewTokenValue: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const TokenAskForm = ({
  newTokenValue,
  onChangeNewTokenValue,
}: ITokenAskForm) => {
  return (
    <S.Content>
      <Typography fontWeight={600} variant={'body1'} component={'h6'}>
        Twój access token
      </Typography>

      <S.TokenInput value={newTokenValue} onChange={onChangeNewTokenValue} />
    </S.Content>
  )
}

export default TokenAskForm
