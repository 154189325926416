import type { FC, ImgHTMLAttributes } from 'react'
import SyncWhiteIcon from 'src/assets/images/sync-renew-alert.svg'

import * as S from './styles'

interface IDataSyncIconProps {
  /** If `true`, then the icon is spinning */
  isSynchronizing?: boolean

  /**
   * All props applicable for native <img/> tag. Some already used properties including `src` and
   *`alt` might by overwritten in this way.
   */
  imgProps?: ImgHTMLAttributes<HTMLImageElement>
}

const DataSyncIcon: FC<IDataSyncIconProps> = (props) => {
  return (
    <S.SyncIconWrapper isSynchronizing={props.isSynchronizing}>
      <img src={SyncWhiteIcon} alt='sync_alert' {...props.imgProps} />
    </S.SyncIconWrapper>
  )
}

export default DataSyncIcon
