import { useEffect, useState, useRef, RefObject } from 'react'

import type { TFormattedTask } from '@/pages/Dashboard/types'

interface IUseIsOverflowing {
  textRef: RefObject<HTMLHeadingElement>
  isOverflowing: boolean
}

export function useIsOverflowing(task: TFormattedTask): IUseIsOverflowing {
  const textRef = useRef<HTMLHeadingElement>(null)
  const [isOverflowing, setIsOverflowing] = useState(false)

  useEffect(() => {
    const checkOverflow = () => {
      const current = textRef.current
      if (current) {
        const overflow = current.scrollWidth > current.clientWidth
        setIsOverflowing(overflow)
      }
    }

    checkOverflow()
    window.addEventListener('resize', checkOverflow)

    return () => {
      window.removeEventListener('resize', checkOverflow)
    }
  }, [task])

  return {
    isOverflowing,
    textRef,
  }
}
