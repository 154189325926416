import { styled } from '@mui/material/styles'

export const ContainerWrapper = styled('div')(({ theme }) => ({
  maxWidth: 480,
  padding: 40,
  backgroundColor: theme.superDarkPurple,
  borderRadius: 8,
  marginLeft: 'auto',
  marginRight: 'auto',
}))

export const HeaderContainer = styled('h4')(({ theme }) => ({
  ...theme.typography.h4,
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.white,
  fontSize: theme.fonts.h4,
  textAlign: 'start',
  marginTop: 0,
  marginBottom: 48,
  letterSpacing: 1,
}))
