import TokenAskForm from 'src/components/organisms/SettingsList/BasicSettings/components/TokenAskForm'

import * as S from './styles'
import React from 'react'
import { ThemedButton } from 'src/components/atoms/ThemedButton/ThemedButton'
import TextLink from 'src/components/atoms/TextLink'
import config from 'src/config'

interface IProps {
  token: string
  updateToken: () => void
  onChangeNewTokenValue: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const VerifyTokenTemplate = ({
  token,
  onChangeNewTokenValue,
  updateToken,
}: IProps) => (
  <S.Center>
    <S.Container>
      <S.TokenInputWrapper>
        <TokenAskForm
          newTokenValue={token}
          onChangeNewTokenValue={onChangeNewTokenValue}
        />
        <TextLink href={config.asanaTokenPDFLink} target={'_blank'}>
          Skąd wziąć access token
        </TextLink>
      </S.TokenInputWrapper>
      <ThemedButton size={'medium'} label={'Zapisz'} onClick={updateToken} />
    </S.Container>
  </S.Center>
)

export default VerifyTokenTemplate
