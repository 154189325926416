import { parseDate } from 'src/utils/parseDateToDefaultFormat'
import {
  addDays,
  isAfter,
  isBefore,
  isSameDay,
  isFriday,
  isMonday,
  differenceInCalendarDays,
} from 'date-fns'

import type { ListIteratee } from 'lodash'
import type { TAddParsedDate, TFormattedTask } from 'src/pages/Dashboard/types'

export const addParsedDate: TAddParsedDate = (value) => {
  return {
    ...value,
    parsedDate: parseDate(value.due_on),
  }
}

export const checkIfForToday: ListIteratee<TFormattedTask> = (task) => {
  return isSameDay(task.parsedDate, Date.now())
}

export const checkIfForTomorrow: ListIteratee<TFormattedTask> = (task) => {
  return isSameDay(task.parsedDate, addDays(Date.now(), 1))
}

export const checkIfForNextMonday: ListIteratee<TFormattedTask> = (task) => {
  const differenceInDays = differenceInCalendarDays(task.parsedDate, Date.now())

  return (
    isFriday(Date.now()) && isMonday(task.parsedDate) && differenceInDays === 3
  )
}

export const checkIfUpcoming: ListIteratee<TFormattedTask> = (task) => {
  return isAfter(task.parsedDate, addDays(Date.now(), 1))
}

export const checkIfOutdated: ListIteratee<TFormattedTask> = (task) => {
  return isBefore(task.parsedDate, addDays(Date.now(), -1))
}
