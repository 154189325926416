import useMessage from 'src/hooks/useMessage'
import { SnackbarContext } from 'src/providers/SnackbarProvider'

import type { MouseEvent } from 'react'

interface IUseClipboardCopy {
  copyToClipboard: (
    e: MouseEvent<HTMLButtonElement>,
    content: string,
    snackbarMessage: string,
  ) => Promise<void>
}

export function useClipboardCopy(): IUseClipboardCopy {
  const { setSnackbarMessage } = useMessage(SnackbarContext)

  const copyToClipboard: IUseClipboardCopy['copyToClipboard'] = async function (
    e: MouseEvent<HTMLButtonElement>,
    content: string,
    snackbarMessage: string,
  ) {
    e.preventDefault()
    await navigator.clipboard.writeText(content)
    setSnackbarMessage(snackbarMessage)
  }

  return {
    copyToClipboard,
  }
}
