import { useMemo } from 'react'

export function useDataSyncWorker(): Worker {
  return useMemo(() => {
    try {
      return new Worker(
        new URL(
          //! Important: This path must be relative to the place where this hook is being used!!!
          'src/workers/data-sync/index.ts',
          import.meta.url,
        ),
      )
    } catch (error) {
      console.error('Failed to create worker:', error)
      return null as never
    }
  }, [])
}
