import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { NavigateFunction } from 'react-router-dom'

import config from './config'
import setToken from './utils/setToken'
import LocalStorageManager from './utils/LocalStorageManager'

const httpLink = createHttpLink({
  uri: config.apiUrl,
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(config.tokenName)

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const logoutLink = (navigate: NavigateFunction) =>
  onError(({ graphQLErrors }: ErrorResponse) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        // @ts-ignore
        if (error.debugMessage === 'No Authorization') {
          LocalStorageManager.clear(config.tasksStorageName)
          navigate('/verify-token')
          return
        }
        switch (error.extensions.category) {
          case 'authentication': {
            setToken(null)
            navigate('/signin')
            LocalStorageManager.clear(config.tasksStorageName)
            break
          }

          default: {
            console.log('[GRAPHQL]: Unhandled graphql error')
          }
        }
      })
    }
  })

export const client = (navigate: NavigateFunction) =>
  new ApolloClient({
    uri: process.env.BACKEND_URL,
    link: from([authLink, logoutLink(navigate), httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        User: {
          fields: {
            favorite_workspaces: {
              merge(_, incoming) {
                return incoming
              },
            },
          },
        },
      },
    }),
  })
