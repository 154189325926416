import { FC } from 'react'

import { TFormattedTask } from 'src/pages/Dashboard/types'
import ColorSquare from 'src/components/atoms/ColorSquare/ColorSquare'

import FieldBase from '../_FieldBase'
import * as S from './styles'
import { getWorkspaceName } from './utils'

interface IWorkspaceNameFieldProps {
  areProjectsVisible?: boolean
  task: TFormattedTask
}

const WorkspaceNameField: FC<IWorkspaceNameFieldProps> = ({
  areProjectsVisible,
  task,
}) => {
  return (
    <FieldBase
      scope='row'
      align='left'
      isColoredCell={!areProjectsVisible}
      fieldType='WORKSPACE'
    >
      <S.WorkspaceFieldWrapper>
        <S.Tooltip placement='top' title={getWorkspaceName(task)}>
          <S.Link href={task.permalink_url} target={'_blank'}>
            <ColorSquare color={task.workspace.userColor} />
            <S.WorkspaceName>{getWorkspaceName(task)}</S.WorkspaceName>
          </S.Link>
        </S.Tooltip>
      </S.WorkspaceFieldWrapper>
    </FieldBase>
  )
}

export default WorkspaceNameField
