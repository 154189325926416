import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import Header from 'src/components/organisms/Header'
import Loader from 'src/components/molecules/Loader'
import Sidebar from 'src/components/organisms/Sidebar'

import * as S from './styles'
import { TLayoutViewProps } from './types'
import { omitSidebarPaths } from './consts'
import LocalStorageManager from 'src/utils/LocalStorageManager'
import config from 'src/config'

const Layout = ({
  children,
  isLoaderVisible,
  isSidebarVisible,
  lists,
}: TLayoutViewProps) => {
  const sidebarState = LocalStorageManager.get(config.sidebarStateName)

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(
    Boolean(sidebarState === null ? true : sidebarState === 'true'),
  )
  const location = useLocation()

  const handleSidebarOpen = () => {
    setIsSidebarOpen((prevState) => {
      LocalStorageManager.set(config.sidebarStateName, `${Boolean(!prevState)}`)

      return !prevState
    })
  }

  const omitPathname = omitSidebarPaths.includes(location.pathname)

  return (
    <S.LayoutWrapper>
      {isLoaderVisible && (
        <S.LoaderWrapper>
          <Loader />
        </S.LoaderWrapper>
      )}
      <Header
        isMenuButton={isSidebarVisible && !omitPathname}
        onMenuClick={handleSidebarOpen}
      />
      <S.Content>
        {isSidebarVisible && isSidebarOpen && !omitPathname && (
          <Sidebar lists={lists} />
        )}
        <S.ChildrenWrapper>{children}</S.ChildrenWrapper>
      </S.Content>
    </S.LayoutWrapper>
  )
}

export default Layout
