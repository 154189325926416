import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router'
import { useCallback, useEffect } from 'react'
import { useUserContext } from 'src/hooks/useUserContext'

import { LoginWithGoogleDocument } from 'src/__generated__/graphql'
import SignInTemplate from 'src/components/templates/SignIn/SignIn'
import { TGoogleButtonCallback } from 'src/components/atoms/GoogleLoginButton/types'
import setToken from 'src/utils/setToken'
import LocalStorageManager from 'src/utils/LocalStorageManager'
import config from 'src/config'

const SignInPage = () => {
  const [getToken] = useMutation(LoginWithGoogleDocument)
  const navigate = useNavigate()
  const { setUser } = useUserContext()

  const handleGoogleSignIn = useCallback(
    async (res: TGoogleButtonCallback) => {
      getToken({ variables: { id_token: res.credential } })
        .then((response) => {
          const { token, user } = response.data?.googleAuthLogin!
          if (token) {
            setToken(token)

            if (user) {
              setUser(user)

              if (!user.asanaPat) {
                navigate('/verify-token', { replace: true })
                return
              }
            }

            navigate('/', { replace: true })
          }
        })
        .catch((error) => {
          console.log('Failed to login!', error)
        })
    },
    [getToken, navigate, setUser],
  )

  // Clear local storage tasks
  useEffect(() => {
    LocalStorageManager.clear(config.tasksStorageName)
  }, [])

  return <SignInTemplate signIn={handleGoogleSignIn} />
}

export default SignInPage
