import React from 'react'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import * as S from './styles'
import { ISnackbar } from './types'
import LocalStorageManager from 'src/utils/LocalStorageManager'
import config from 'src/config'

const Snackbar = ({
  snackbarOpen,
  setSnackbarOpen,
  snackbarMessage,
  additionalProps,
  snackbarIcon,
}: ISnackbar) => {
  const sidebarState = LocalStorageManager.get(config.sidebarStateName)

  const isSidebarOpen = Boolean(
    sidebarState === null ? true : sidebarState === 'true',
  )

  const snackbarAction = (
    <React.Fragment>
      <IconButton
        size='small'
        aria-label='close'
        color='inherit'
        onClick={() => {
          setSnackbarOpen(false)
        }}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </React.Fragment>
  )

  const messageWithIcon = () => {
    if (snackbarIcon) {
      return (
        <S.MessageIcon>
          {snackbarIcon}
          {snackbarMessage}
        </S.MessageIcon>
      )
    }
    return snackbarMessage
  }

  return (
    <S.Snackbar
      open={snackbarOpen}
      autoHideDuration={3000}
      ClickAwayListenerProps={{ onClickAway: () => null }}
      onClose={() => {
        setSnackbarOpen(false)
      }}
      message={messageWithIcon()}
      action={snackbarAction}
      isError={additionalProps.isError}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: isSidebarOpen ? 'center' : 'left',
      }}
    />
  )
}

export default Snackbar
