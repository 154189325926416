import Navigation from 'src/components/organisms/Navigation'

import * as S from './styles'
import BasicSettings from 'src/components/organisms/SettingsList/BasicSettings/BasicSettings'
// import NotificationSettings from 'src/components/organisms/SettingsList/NotificationSettings/NotificationSettings'

const SettingsTemplate = () => {
  return (
    <S.Center>
      <S.Container>
        <Navigation />

        <BasicSettings />
        {/*<NotificationSettings />*/}
      </S.Container>
    </S.Center>
  )
}

export default SettingsTemplate
