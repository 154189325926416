const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  tokenName: 'FingowebAccessToken',
  tasksStorageName: 'AsanaUserTasks',
  prioritiesStorageName: 'AsanaPriorities',
  sidebarStateName: 'isSidebarOpen',
  lastSyncStorageName: 'FingowebAsanaLastSync',
  asanaLink: 'https://app.asana.com',
  asanaWorkspaceLink: 'https://app.asana.com/0/home/',
  asanaTokenPDFLink:
    'https://docs.google.com/document/d/1blcqsOy08Gzax-VeP3f_EhUg0voU5RfzrnZQ0s8uCVU/edit',
}

export default config
