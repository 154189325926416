import { Snackbar as MaterialSnackbar } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Snackbar = styled(MaterialSnackbar, {
  shouldForwardProp: (prop: string) => prop !== 'isError',
})<{ isError?: boolean }>(({ theme, isError }) => ({
  '& .MuiSnackbarContent-root': {
    backgroundColor: isError ? theme.red : theme.darkBlue,
    minWidth: '314px',
    height: '50px',
  },
}))

export const MessageIcon = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
}))
