import { RefObject, useRef } from 'react'

interface IUseCalendarPopupOpenerRef {
  calendarPopupOpenerRef: RefObject<HTMLButtonElement>

  openCalendarPopup: () => void
}

export function useCalendarPopupOpenerRef(): IUseCalendarPopupOpenerRef {
  const calendarPopupOpenerRef = useRef<HTMLButtonElement>(null)

  function openCalendarPopup() {
    if (!(calendarPopupOpenerRef.current instanceof HTMLElement)) {
      throw new Error(
        'Value of a reference calendarPopupOpenerRef from useRef hook is not a HTML Element!',
      )
    }

    calendarPopupOpenerRef.current.click()
  }

  return {
    calendarPopupOpenerRef,
    openCalendarPopup,
  }
}
