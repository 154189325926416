import useMessage from 'src/hooks/useMessage'
import { useMutation } from '@apollo/client'
import { useTasksContext } from 'src/hooks/useTasksContext'

import _get from 'lodash/get'
import { ChangePriorityDocument } from 'src/__generated__/graphql'

import type { FC } from 'react'
import type {
  TFormattedTask,
  TPriority,
  TTask,
} from 'src/pages/Dashboard/types'

import { NO_PRIORITY_SET } from './consts'
import { SelectChangeEvent, Typography } from '@mui/material'
import { SnackbarContext } from 'src/providers/SnackbarProvider'
import ArrowDropDownIcon from 'src/components/atoms/ArrowDropDownIcon/ArrowDropDownIcon'

import FieldBase from '../_FieldBase'

import * as S from './styles'

type TPriorityId = TPriority['id'] | typeof NO_PRIORITY_SET

interface IPriorityPickerFieldProps {
  task: TFormattedTask
  priorities: TPriority[]
}

const PriorityPickerField: FC<IPriorityPickerFieldProps> = ({
  task,
  priorities,
}) => {
  const { updateTaskLocally } = useTasksContext()

  const { setSnackbarMessage } = useMessage(SnackbarContext)

  const [changePriority, { loading: changePriorityLoading }] = useMutation(
    ChangePriorityDocument,
    {
      onError: () => {
        setSnackbarMessage('Nie udało sie zmienić priorytetu', {
          isError: true,
        })
      },
      onCompleted: () => {
        setSnackbarMessage('Udało się zmienić priorytet!')
      },
    },
  )

  const onPriorityChange = (e: SelectChangeEvent<TPriorityId>) => {
    const newPriority: TTask['priority'] =
      e.target.value === NO_PRIORITY_SET
        ? null
        : priorities.find((priority) => priority.id === e.target.value)

    updateTaskLocally(task.id, {
      priority: newPriority,
    })

    if (!changePriorityLoading) {
      changePriority({
        variables: {
          gid: task.gid,
          priorityId: _get(newPriority, 'id', null),
        },
      })
    }
  }

  return (
    <FieldBase align='left' fieldType='PRIORITY_PICKER'>
      <S.FormControl>
        <S.Select
          id={'priority-select-' + task.gid}
          value={_get(task, 'priority.id', 'NO_PRIORITY_SET')}
          onChange={(event) =>
            onPriorityChange(event as SelectChangeEvent<TPriorityId>)
          }
          IconComponent={!task.priority?.id ? ArrowDropDownIcon : () => null}
          displayEmpty
          isTableField
          MenuProps={{
            PaperProps: {
              sx: (theme) => ({
                bgcolor: theme.palette.mode === 'dark' ? '#29283F' : '#fff',
              }),
            },
          }}
        >
          <S.MenuItem value={'NO_PRIORITY_SET' as TPriorityId}>
            <Typography
              variant='h5'
              component='h3'
              lineHeight={1.4}
              style={{ padding: '0 10px' }}
            >
              --
            </Typography>
          </S.MenuItem>

          {priorities.reverse().map((priority) => (
            <S.MenuItem value={priority.id} key={priority.id}>
              <S.Priority color={priority.color}>{priority.name}</S.Priority>
            </S.MenuItem>
          ))}
        </S.Select>
      </S.FormControl>
    </FieldBase>
  )
}

export default PriorityPickerField
