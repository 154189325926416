import { createContext, Dispatch, SetStateAction } from 'react'

import type { PaletteMode } from '@mui/material'

export interface IColorPaletteContext {
  paletteModeInUse: PaletteMode
  changePaletteMode: Dispatch<SetStateAction<PaletteMode>>
}

export const ColorPaletteContext = createContext<IColorPaletteContext>(
  {} as IColorPaletteContext,
)
