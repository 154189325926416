import useURL from 'src/hooks/useURL'
import { useMemo, useCallback } from 'react'

import { get, orderBy, sortBy } from 'lodash'

import { SortOrder } from 'src/__generated__/graphql'
import { TFormattedTask } from 'src/pages/Dashboard/types'
import { ETaskPaths } from 'src/components/organisms/SortingBar/enums'

interface IUseTasksSorterResult {
  /**
   * Sorts an array of tasks based on the sorting key and order from the url.
   *
   * If no sorting key is provided, the tasks will be sorted by date, priority, name, and workspace in that order.
   *
   * When sorting by priority, tasks are sorted such that those with a higher priority come first,
   * with tasks having `priority` set to `null` or `undefined` being sorted to the end.
   *
   * @param {TFormattedTask[]} tasksToBeSorted - An array of tasks to be sorted.
   * @returns {TFormattedTask[]} - A new array of tasks sorted according to the specified configuration.
   */
  sortTasks: (tasksToBeSorted: TFormattedTask[]) => TFormattedTask[]
}

export function useTasksSorter(): IUseTasksSorterResult {
  const { URL: URLAddress } = useURL()

  const sortingKey = useMemo(
    () => URLAddress['sortBy'] as keyof TFormattedTask,
    [URLAddress['sortBy']],
  )
  const sortingOrder = useMemo(
    () => URLAddress['sortOrder'] as SortOrder,
    [URLAddress['sortOrder']],
  )

  const sortTasks = useCallback(
    (tasks: TFormattedTask[]): TFormattedTask[] => {
      let newTasksArray: TFormattedTask[] = []

      if (!sortingKey) {
        newTasksArray = orderBy(
          tasks,
          [
            (task) => get(task, ETaskPaths.date),
            (task) => get(task, ETaskPaths.priority) || '',
            (task) =>
              get(task, ETaskPaths.name)
                ?.toString()
                .toLowerCase()
                .replace('[', ''),
            (task) => get(task, ETaskPaths.workspace)?.toString().toLowerCase(),
          ],
          ['asc', 'desc', 'asc', 'asc'],
        )

        return newTasksArray
      } else {
        newTasksArray = sortBy(
          tasks,
          (task) => get(task, sortingKey)?.toString().toLowerCase(),
        )
      }

      if (sortingOrder === SortOrder.Desc) {
        newTasksArray = newTasksArray.reverse()
      }

      if (sortingKey.toString() === ETaskPaths.priority) {
        return sortBy(newTasksArray, (task) => !task.priority)
      }

      return newTasksArray
    },
    [sortingKey, sortingOrder],
  )

  return {
    sortTasks,
  }
}
