import useMessage from 'src/hooks/useMessage'
import { useDataSyncWorker, useTasksCategorizedBasedOnDueDate } from './hooks'
import { useTasksContext } from 'src/hooks/useTasksContext'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useContext, useEffect, useMemo, useRef } from 'react'
import { useUserContext } from 'src/hooks/useUserContext'

import { SyncContext } from 'src/providers/SyncProvider'
import { SnackbarContext } from 'src/providers/SnackbarProvider'

import config from 'src/config'
import {
  AsanaSyncDocument,
  PrioritiesDocument,
} from 'src/__generated__/graphql'
import LocalStorageManager from 'src/utils/LocalStorageManager'

import type { TPriority } from './types'
import type { SyncWorkersMessages } from 'src/workers/data-sync/types'

import Loader from 'src/components/molecules/Loader/Loader'
import DataSyncIcon from 'src/components/atoms/DataSyncIcon'
import SyncErrorIcon from 'src/assets/images/sync-renew-error.svg'
import Dashboard from 'src/components/templates/Dashboard/Dashboard'
import EmptyState from './EmptyState'

//import SortingBar from 'src/components/organisms/SortingBar'

const DashboardPage = () => {
  const syncWorker = useDataSyncWorker()

  const isFirstRender = useRef(true)

  const { storeLastSyncTime } = useContext(SyncContext)
  const { setSnackbarMessage } = useMessage(SnackbarContext)
  const { user, setIsUserSynchronizing } = useUserContext()

  const cachedPriorities: TPriority[] = LocalStorageManager.get(
    config.prioritiesStorageName,
  ) as TPriority[]

  const variables = useMemo(
    () => ({
      first: 9999,
      userId: user.id,
    }),
    [user],
  )

  const { tasksToRender, totalNumberOfTasks } =
    useTasksCategorizedBasedOnDueDate()

  const { cachedTasks, fetchTasks, tasksAreLoading, tasksHaveBeenLoaded } =
    useTasksContext()

  const [fetchPriorities, prioritiesResult] = useLazyQuery(PrioritiesDocument, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: ({ asanaPriorities }) => {
      if (asanaPriorities && asanaPriorities.data instanceof Array) {
        LocalStorageManager.set(
          config.prioritiesStorageName,
          asanaPriorities!.data,
        )
      }
    },
  })

  const [asanaSync, { loading: asanaSyncLoading }] = useMutation(
    AsanaSyncDocument,
    {
      onError: () => {
        setSnackbarMessage(
          'Błąd synchronizacji',
          {
            isError: true,
          },
          <img src={SyncErrorIcon} alt='sync_error' />,
        )
      },
      onCompleted: async () => {
        storeLastSyncTime()
        setSnackbarMessage('Zsynchronizowano z Twoją Asaną!')
        await fetchTasks({ variables })
        setIsUserSynchronizing(false)
      },
    },
  )

  useEffect(() => {
    if (syncWorker) {
      syncWorker.postMessage('START')

      const outgoingMessageHandler = ({
        data: messageCode,
      }: MessageEvent<SyncWorkersMessages.Outgoing>) => {
        switch (messageCode) {
          // -------
          // -
          // -------
          case 'SYNC':
            if (user.id && !asanaSyncLoading && !tasksAreLoading) {
              setIsUserSynchronizing(true)
              asanaSync().then(() => {
                fetchTasks({ variables })
              })
              fetchPriorities()
            }

            break

          // -------
          // -
          // -------
          default:
            console.error(`Unexpected worker outgoing message: ${messageCode}`)
            break
        }
      }

      syncWorker.addEventListener('message', outgoingMessageHandler)

      return () => {
        syncWorker.postMessage('STOP')
        syncWorker.removeEventListener('message', outgoingMessageHandler)
      }
    }
  }, [
    fetchTasks,
    fetchPriorities,
    asanaSync,
    user,
    asanaSyncLoading,
    tasksAreLoading,
    variables,
    syncWorker,
  ])

  const prioritiesData: TPriority[] = useMemo(() => {
    const priorities =
      cachedPriorities || prioritiesResult.data?.asanaPriorities?.data || []

    return priorities
  }, [prioritiesResult.data, cachedPriorities])

  useEffect(() => {
    if (
      isFirstRender.current &&
      user.id &&
      !tasksAreLoading &&
      !asanaSyncLoading
    ) {
      isFirstRender.current = false
      fetchTasks({ variables }).then(() => {
        setIsUserSynchronizing(true)
        return asanaSync()
      })
      fetchPriorities()
    }
  }, [
    isFirstRender.current,
    user,
    tasksAreLoading,
    fetchTasks,
    fetchPriorities,
    asanaSync,
    cachedTasks,
    variables,
  ])

  useEffect(() => {
    if (asanaSyncLoading) {
      setSnackbarMessage(
        'Synchronizacja...',
        {},
        <DataSyncIcon isSynchronizing />,
      )
    }
  }, [asanaSyncLoading])

  if (!tasksHaveBeenLoaded) return <Loader isCentered />
  else if (totalNumberOfTasks === 0) return <EmptyState />

  return (
    <>
      {/* <SortingBar activeField={sortingKey} activeOrder={sortingOrder} /> */}
      <Dashboard groupedTasks={tasksToRender} priorities={prioritiesData} />
    </>
  )
}

export default DashboardPage
