import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'calc(100vh - 100px)',
}))

export const MainHeader = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.h3,
  margin: '20px 0 8px 0',
}))

export const Emoji = styled('h2')(({ theme }) => ({
  fontSize: theme.fonts.h1,
  margin: 0,
}))

export const Text = styled(Typography)(() => ({
  margin: 0,
}))
