import config from 'src/config'

const setToken = (token: string | null) => {
  if (token !== null) {
    localStorage.setItem(config.tokenName, token)
    return
  }

  localStorage.removeItem(config.tokenName)
}

export default setToken
