import { styled } from '@mui/material/styles'
import { clickableIconsWrapperSelector } from 'src/components/atoms/ClickableIcons/consts'

import { TableRow as MaterialTableRow } from '@mui/material'
import { AllIconsWrapperHoverStyles } from 'src/components/atoms/ClickableIcons/styles'

export const TableRow = styled(MaterialTableRow)(({ theme }) => ({
  cursor: 'pointer',
  height: '39px',
  maxHeight: '39px',

  '&:not(&:hover)': {
    [clickableIconsWrapperSelector]: {
      '&.copy-clickable-icons-wrapper': {
        width: '0',
        marginLeft: 0,
      },
    },
  },

  '&:hover': {
    backgroundColor: theme.tableHover,

    [clickableIconsWrapperSelector]: {
      ...AllIconsWrapperHoverStyles,

      '&.copy-clickable-icons-wrapper': {
        width: 'auto',
      },
    },
  },
}))
