import { styled } from '@mui/material/styles'

export const Sidebar = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  backgroundColor: theme.sidebarBackground,
  borderTop: `1px solid ${theme.mediumBackgroundShadow}`,
  borderRight: `1px solid ${theme.mediumBackgroundShadow}`,
  maxWidth: 240,
  width: '100%',
  height: 'calc(100vh - 50px)',
  padding: '16px 8px',
  paddingBottom: 0,
  position: 'sticky',
  top: 50,
  overflowY: 'auto',
  overflowX: 'hidden',

  '::-webkit-scrollbar': {
    width: 5,
    backgroundColor: theme.scrollbarBackground,
  },

  '::-webkit-scrollbar-thumb': {
    background: theme.scrollbarTrack,
    borderBadius: 10,
    cursor: 'pointer',
  },
}))

export const IconWrapper = styled('div')(() => ({
  borderRadius: 4,
  width: 24,
  height: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& img': {
    transform: 'scale(1.2)',
  },
}))

export const SyncInfoWrapper = styled('div')(({ theme }) => ({
  position: 'sticky',
  backgroundColor: theme.sidebarBackground,
  bottom: 0,
  left: 0,
  zIndex: 1,
  width: 'calc(100% + 16px)',
  marginLeft: '-8px',
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  boxShadow: '0px -4px 8px 0px rgba(0, 0, 0, 0.03)',
  overflow: 'visible',
  overflowWrap: 'break-word',
}))
