import { useEffect, useRef } from 'react'

import config from 'src/config'

import { IGoogleLoginButtonProps } from './types'

export const GoogleLoginButton = ({ signIn }: IGoogleLoginButtonProps) => {
  const divRef = useRef(null)

  useEffect(() => {
    // @ts-ignore
    window.google.accounts.id.initialize({
      client_id: config.clientId,

      callback: signIn,
    })

    // @ts-ignore
    window.google.accounts.id.renderButton(divRef.current, {
      theme: 'filled_blue',
      shape: 'pill',
      width: 400,
      size: 'large',
    })
  }, [signIn])

  return <div ref={divRef} />
}
