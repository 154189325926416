import GoBackButton from 'src/components/atoms/GoBackButton'

import * as S from './styles'
import { Typography } from '@mui/material'

interface IProps {
  withGoBackButton?: boolean
}

const Navigation = ({ withGoBackButton = true }: IProps) => {
  return (
    <S.Navigation>
      {withGoBackButton && <GoBackButton />}{' '}
      <Typography variant={'h6'} fontWeight={600}>
        Ustawienia
      </Typography>
    </S.Navigation>
  )
}

export default Navigation
