import { useTheme } from '@mui/material'

import FingowebSVG from 'src/assets/images/fingoweb.svg'
import FingowebWhiteSVG from 'src/assets/images/fingoweb-white.svg'

import * as S from './styles'

const Logo = () => {
  const theme = useTheme()

  return (
    <S.Link href='https://www.fingoweb.com/' target='_blank'>
      <img
        src={theme.palette.mode === 'dark' ? FingowebWhiteSVG : FingowebSVG}
        alt='fingoweb-logo'
      />
    </S.Link>
  )
}

export default Logo
