import { format, parse } from 'date-fns'
import { asanaDateFormat } from '../consts/dateConsts'

/**
 * Parses a date string in `yyyy-MM-dd` format into a JavaScript `Date` object.
 *
 * If the input string is `null`, the function returns `0`.
 *
 * @param {string | null} valueToParse - The string to be parsed into a `Date` object. If `null`, the function returns `0`.
 * @returns {Date | 0} - A `Date` object representing the parsed date, or `0` if the input is `null`.
 *
 * @example
 * // Returns a Date object for August 13, 2024
 * const date = parseDate('2024-08-13');
 *
 * @example
 * // Returns 0 as input is null
 * const result = parseDate(null);
 */
export function parseDate(valueToParse: string | null): Date | 0 {
  return valueToParse !== null
    ? parse(valueToParse, asanaDateFormat, Date.now())
    : 0
}

/**
 * Converts a `Date` object or timestamp into a string formatted as `yyyy-MM-dd`.
 *
 * @param value - The `Date` object or timestamp to be formatted, or `null` if no value is provided.
 * @returns A string containing the formatted date in `yyyy-MM-dd` format, or `null` if the input is `null`.
 */
export function formatDateIntoAsanaFormat(
  value: Date | number | null,
): string | null {
  return value !== null ? format(value, asanaDateFormat) : null
}
