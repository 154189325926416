import { useMutation } from '@apollo/client'
import { useUserContext } from 'src/hooks/useUserContext'

import {
  FavouriteWorkspaceCreateDocument,
  FavouriteWorkspaceDeleteDocument,
} from 'src/__generated__/graphql'

import LayoutView from './Layout.view'
import { TLayoutContainerProps } from './types'
import {
  formatLists,
  updateCacheAfterCreate,
  updateCacheAfterDelete,
} from './utils'

const LayoutContainer = ({
  children,
  isLoaderVisible,
  isSidebarVisible,
}: TLayoutContainerProps) => {
  const {
    user: { id, asanaWorkspaces, favouriteWorkspaces },
  } = useUserContext()

  const [createFavouriteWorkspace] = useMutation(
    FavouriteWorkspaceCreateDocument,
    {
      update: updateCacheAfterCreate,
    },
  )
  const [deleteFavouriteWorkspace] = useMutation(
    FavouriteWorkspaceDeleteDocument,
    {
      update: updateCacheAfterDelete,
    },
  )

  const addToFavourites = (workspaceId: string) => {
    if (!id) {
      return
    }

    createFavouriteWorkspace({
      variables: {
        input: {
          user_id: id,
          asana_workspace_id: workspaceId,
        },
      },
    })
  }

  const deleteFromFavourites = (workspaceId: string) => {
    deleteFavouriteWorkspace({
      variables: { id: workspaceId },
    })
  }

  return (
    <LayoutView
      isLoaderVisible={isLoaderVisible}
      isSidebarVisible={isSidebarVisible}
      lists={formatLists(
        asanaWorkspaces || [],
        favouriteWorkspaces || [],
        addToFavourites,
        deleteFromFavourites,
      )}
    >
      {children}
    </LayoutView>
  )
}

export default LayoutContainer
