import { FC } from 'react'
import { useIsOverflowing } from './hooks'
import { useClipboardCopy } from 'src/hooks/useClipboardCopy'

import type { TFormattedTask } from 'src/pages/Dashboard/types'

import FieldBase from '../_FieldBase'
import { Typography, Tooltip } from '@mui/material'
import { ContentCopy, Link } from '@mui/icons-material'
import ClickableIcons from 'src/components/atoms/ClickableIcons'

import * as S from './styles'

interface ITaskNameFieldProps {
  task: TFormattedTask
}

const TaskNameField: FC<ITaskNameFieldProps> = ({ task }) => {
  const { copyToClipboard } = useClipboardCopy()
  const { isOverflowing, textRef } = useIsOverflowing(task)

  return (
    <FieldBase scope='row' align='left' fieldType='TASK_NAME'>
      <S.TaskNameWrapper>
        <Tooltip
          placement='top'
          title={task.name}
          disableHoverListener={!isOverflowing}
        >
          <S.Link href={task.permalink_url} target={'_blank'}>
            <Typography variant='h5' component='h3' noWrap ref={textRef}>
              {task.name}{' '}
              {task.asana_parent_task_name !== null && (
                <S.ParentTaskNameWrapper>
                  <span className='arrow-icon'>‹</span>
                  <span>{task.asana_parent_task_name}</span>
                </S.ParentTaskNameWrapper>
              )}
            </Typography>
          </S.Link>
        </Tooltip>

        <ClickableIcons
          className='copy-clickable-icons-wrapper'
          actions={[
            {
              tooltip: 'Kopiuj nazwę taska',
              icon: <ContentCopy />,
              onClick: (e) =>
                copyToClipboard(e, task.name, 'Skopiowano nazwę taska!'),
            },
            {
              tooltip: 'Kopiuj link do taska',
              icon: <Link />,
              onClick: (e) =>
                copyToClipboard(
                  e,
                  task.permalink_url!,
                  'Skopiowano link do taska!',
                ),
            },
          ]}
        />
      </S.TaskNameWrapper>
    </FieldBase>
  )
}

export default TaskNameField
