import { darken, lighten, styled } from '@mui/material/styles'

export const Container = styled('div')(({ theme }) => ({
  padding: 24,
  borderRadius: 8,
  width: '100%',
  marginBottom: 32,
  backgroundColor: theme.lightBackground,
  border: `1px solid ${theme.mainBorderColor}`,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
}))

export const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  color:
    theme.palette.mode === 'dark'
      ? darken(theme.mainTextColor, 0.4)
      : lighten(theme.mainTextColor, 0.2),
}))

export const ItemWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}))

export const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '4px',
}))
