import { TasksTableBody } from './components'
import { Paper, Table, TableBody, Typography } from '@mui/material'

import * as S from './styles'
import type { ITasksTableBody } from './components/TasksTableBody'

interface ITasksTable extends ITasksTableBody {
  tableName?: string
  withRedCount?: boolean
}

const TasksTable = ({
  tableName,
  tasks,
  priorities,
  withRedCount,
  areProjectsVisible,
}: ITasksTable) => {
  const withTasks = tasks && tasks.length > 0

  return (
    <S.Container>
      <S.TableContainer component={Paper} elevation={4}>
        <S.TableTitle withRedCount={withRedCount}>
          <Typography variant='h4' component='h2'>
            {tableName}
          </Typography>
          <Typography variant='h5' component='h3'>
            {tasks.length}
          </Typography>
        </S.TableTitle>

        <S.Divider />

        <Table aria-label='tasks table' size='small'>
          <TableBody>
            {withTasks && (
              <TasksTableBody
                tasks={tasks}
                priorities={priorities}
                areProjectsVisible={areProjectsVisible}
              />
            )}
          </TableBody>
        </Table>
      </S.TableContainer>
    </S.Container>
  )
}

export default TasksTable
