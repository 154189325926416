import { MenuItem, PaletteMode, Select } from '@mui/material'
import { IThemeSelectorProps } from './types'
import { KeyboardArrowDown } from '@mui/icons-material'

const THEME_OPTIONS: { label: string; value: PaletteMode }[] = [
  { value: 'dark', label: 'Ciemny' },
  { value: 'light', label: 'Jasny' },
]

const ThemeSelector = ({ onThemeChange, theme }: IThemeSelectorProps) => {
  return (
    <Select
      variant={'outlined'}
      value={theme}
      onChange={({ target }) => {
        onThemeChange(target.value as PaletteMode)
      }}
      IconComponent={(props) => <KeyboardArrowDown {...props} />}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {THEME_OPTIONS.map(({ label, value }) => {
        return (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default ThemeSelector
