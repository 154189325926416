import { useState } from 'react'

import CircularProgress from 'src/components/atoms/CircularProgress/CircularProgress'

import * as S from './styles'
import { TMenuButtonProps } from './types'
import { Link, Tooltip } from '@mui/material'

const MenuButton = ({
  children,
  startIcon,
  endIcon,
  url,
  onEndIconClick,
}: TMenuButtonProps) => {
  const [isLoadingTriggered, setIsLoadingTriggered] = useState(false)

  return (
    <Tooltip title={children} enterDelay={800} enterNextDelay={800}>
      <Link target={'_blank'} href={url} underline={'none'}>
        <S.MenuButton
          startIcon={startIcon}
          endIcon={
            endIcon ? (
              <S.EndIconWrapper
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  if (onEndIconClick && !isLoadingTriggered) {
                    onEndIconClick()
                    setIsLoadingTriggered(true)
                  }
                }}
              >
                {isLoadingTriggered ? (
                  <CircularProgress size='20px' />
                ) : (
                  endIcon
                )}
              </S.EndIconWrapper>
            ) : null
          }
        >
          <S.Content>{children}</S.Content>
        </S.MenuButton>
      </Link>
    </Tooltip>
  )
}

export default MenuButton
