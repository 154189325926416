import { Context, ReactNode, useContext } from 'react'

import { TSnackbarProps } from 'src/providers/SnackbarProvider/types'
import { TAdditionalProps } from 'src/components/organisms/Snackbar/types'

const useMessage = (SnackbarContext: Context<TSnackbarProps>) => {
  const { setMessage, setIsSnackbarOpen, setAdditionalProps, setMessageIcon } =
    useContext(SnackbarContext)

  const setSnackbarMessage = (
    message: string,
    additionalProps?: TAdditionalProps,
    messageIcon?: ReactNode,
  ) => {
    setMessage(message)
    setIsSnackbarOpen(true)
    setMessageIcon(messageIcon)
    setAdditionalProps(additionalProps ?? {})
  }

  return {
    setSnackbarMessage,
  }
}

export default useMessage
