import { styled } from '@mui/material/styles'

export const Divider = styled('div')<{
  spacingTop: number
  spacingBottom: number
}>(({ theme, spacingBottom, spacingTop }) => ({
  width: '100%',
  height: 1,
  backgroundColor: theme.mainBorderColor,
  marginTop: spacingTop,
  marginBottom: spacingBottom,
}))
