import { StyledCircularProgress } from './styles'

interface IProps {
  size?: string
}

const CircularProgress = ({ size }: IProps) => (
  <StyledCircularProgress size={size} />
)

export default CircularProgress
