import MenuButton from 'src/components/atoms/MenuButton'

import * as S from './styles'
import { TMenuListProps } from './types'

const MenuList = ({ title, buttons }: TMenuListProps) =>
  buttons.length > 0 ? (
    <S.MenuList>
      <S.MenuListTitle>{title}</S.MenuListTitle>
      {buttons.map((button) => (
        <MenuButton {...button} key={button.id} />
      ))}
    </S.MenuList>
  ) : null

export default MenuList
