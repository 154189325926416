import GoogleLoginButton from 'src/components/atoms/GoogleLoginButton'
import { LoginModal } from 'front-components/dist/cjs'

import { ContainerWrapper, HeaderContainer } from './styles'
import { ISignInContainerProps } from './types'

const LoginContainer = ({ signIn }: ISignInContainerProps) => {
  return (
    <LoginModal
      open
      contentStyles={{
        display: 'flex',
        margin: 'auto',
        marginTop: '250px',
        position: 'relative',
      }}
    >
      <ContainerWrapper>
        <HeaderContainer>Logowanie</HeaderContainer>
        <GoogleLoginButton signIn={signIn} />
      </ContainerWrapper>
    </LoginModal>
  )
}

export default LoginContainer
