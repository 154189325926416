import { useUserContext } from 'src/hooks/useUserContext'

import type { TPriority } from 'src/pages/Dashboard/types'
import type { TGroupedTasks } from 'src/pages/Dashboard/types'

import TasksTable from 'src/components/organisms/TasksTable'

interface IDashboard {
  groupedTasks: TGroupedTasks
  priorities: TPriority[]
}

const Dashboard = ({ groupedTasks, priorities }: IDashboard) => {
  const { lastFetchDate } = useUserContext()

  return (
    <div key={Number(lastFetchDate)}>
      {groupedTasks.outdated.length > 0 && (
        <TasksTable
          tableName='Zadania opóźnione'
          tasks={groupedTasks.outdated}
          priorities={priorities}
          withRedCount
        />
      )}
      {groupedTasks.forToday.length > 0 && (
        <TasksTable
          tableName='Zadania na dziś'
          tasks={groupedTasks.forToday}
          priorities={priorities}
        />
      )}
      {groupedTasks.forTomorrow.length > 0 && (
        <TasksTable
          tableName='Zadania na jutro'
          tasks={groupedTasks.forTomorrow}
          priorities={priorities}
        />
      )}
      {groupedTasks.forMonday.length > 0 && (
        <TasksTable
          tableName='Zadania na poniedziałek'
          tasks={groupedTasks.forMonday}
          priorities={priorities}
        />
      )}
      {groupedTasks.upcomingDate.length > 0 && (
        <TasksTable
          tableName='Nadchodzące zadania'
          tasks={groupedTasks.upcomingDate}
          priorities={priorities}
        />
      )}
      {groupedTasks.withoutDate.length > 0 && (
        <TasksTable
          tableName='Zadania bez daty'
          tasks={groupedTasks.withoutDate}
          priorities={priorities}
        />
      )}
    </div>
  )
}

export default Dashboard
