import {
  FormControl as MaterialFormControl,
  Select as MaterialSelect,
  MenuItem as MaterialMenuItem,
} from '@mui/material'
import { styled } from '@mui/material/styles'

export const FormControl = styled(MaterialFormControl)(() => ({
  padding: 0,
  margin: 0,
  width: '100%',
}))

export const Select = styled(MaterialSelect, {
  shouldForwardProp: (prop: string) => prop !== 'isTableField',
})<{
  isTableField?: boolean
}>(({ theme, isTableField }) => ({
  margin: 0,
  width: '100%',
  height: '39px',
  fontSize: theme.fonts.small,
  '& svg': isTableField && {
    display: 'block',
    position: 'absolute',
    padding: 6,
    top: '50%',
    right: '8px',
    transform: 'translateY(-50%)',
    borderRadius: theme.borderRadius,

    '@media (min-width: 1440px)': {
      right: '12px',
    },
  },

  '.MuiSelect-select': isTableField && {
    border: 'none !important',
    borderRadius: '0 !important',
    backgroundColor: 'transparent !important',
  },

  '.MuiInputBase-input': {
    '@media (min-width: 1440px)': {
      paddingLeft: '16px !important',
    },
  },
}))

export const MenuItem = styled(MaterialMenuItem)(({ theme }) => ({
  paddingTop: '10px',
  paddingBottom: '10px',
  color: theme.palette.mode === 'dark' ? theme.white : theme.darkText,
}))

export const Priority = styled('div')<{
  color?: string
}>(({ theme, color }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: color,
  color: color === theme.pink.toLowerCase() ? theme.darkRed : 'white',
  fontSize: theme.fonts.small,
  fontWeight: 500,
  padding: '0 8px',
  borderRadius: '14px',
  margin: '-4px -2px',
  height: '22px',
  width: 'min-content',
}))
