import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import './index.scss'

import reportWebVitals from './reportWebVitals'
import { AppThemeProvider } from './providers/ThemeProvider'
import { SnackbarProvider } from './providers/SnackbarProvider'
import { SyncProvider } from './providers/SyncProvider'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <AppThemeProvider>
      <SnackbarProvider>
        <SyncProvider>
          <App />
        </SyncProvider>
      </SnackbarProvider>
    </AppThemeProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
