export interface EmptyStateMessage {
  emoji: string
  title: string
}

export const MESSAGES: EmptyStateMessage[] = [
  { emoji: '🎉', title: 'Wszystko gotowe!' },
  { emoji: '🌟', title: 'Jesteś na bieżąco!' },
  { emoji: '💤', title: 'Cisza przed burzą?' },
  { emoji: '🍃', title: 'Chwila wytchnienia' },
  { emoji: '✨', title: 'Wszystko ogarnięte!' },
  { emoji: '🌼', title: 'Czas na kawę?' },
  { emoji: '🏝️', title: 'Nic do zrobienia' },
  { emoji: '🔍', title: 'Nic tu nie ma' },
  { emoji: '🚀', title: 'Wszystko zrobione!' },
  { emoji: '📬', title: 'Skrzynka pusta!' },
  { emoji: '🧘', title: 'Czas na relaks' },
  { emoji: '💡', title: 'Pomysły w drodze' },
  { emoji: '🔧', title: 'Wszystko naprawione' },
]

export function getRandomEmptyStateMessage(): EmptyStateMessage {
  const randomIndex = Math.floor(Math.random() * MESSAGES.length)
  return MESSAGES[randomIndex]
}
