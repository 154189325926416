import {
  Routes,
  Route,
  Outlet,
  Navigate,
  BrowserRouter,
  useNavigate,
} from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import './App.css'

import Layout from './components/templates/Layout'
import DashboardPage from 'src/pages/Dashboard'
import SignInPage from 'src/pages/SignIn'
import VerifyTokenPage from './pages/VerifyToken'
import SettingsPage from './pages/Settings'
import { UserProvider } from './providers/UserProvider'
import TaskContextProvider from './providers/TasksProvider'

import { client } from './apolloClient'

const ApolloWrapper = () => {
  const navigate = useNavigate()

  return (
    <ApolloProvider client={client(navigate)}>
      <Outlet />
    </ApolloProvider>
  )
}

const App = () => (
  <div className='App'>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<ApolloWrapper />}>
          <Route
            path='/'
            element={
              <UserProvider>
                <TaskContextProvider>
                  <Layout isSidebarVisible>
                    <Outlet />
                  </Layout>
                </TaskContextProvider>
              </UserProvider>
            }
          >
            <Route path='/' element={<DashboardPage />} index />
            <Route path='settings' element={<SettingsPage />} />
            <Route path='verify-token' element={<VerifyTokenPage />} />
          </Route>
          <Route path='/signin' element={<SignInPage />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </div>
)

export default App
