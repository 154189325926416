import { Link as MaterialLink } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Link = styled(MaterialLink)({
  display: 'flex',
  alignItems: 'center',
  width: '100px',
  height: '30px',
  margin: 0,

  '& > img': {
    width: '100%',
    height: '100%',
  },
})
