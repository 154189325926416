import { createContext, useState } from 'react'

import { IProps, TSyncProps } from './types'
import LocalStorageManager from 'src/utils/LocalStorageManager'
import config from 'src/config'
import getCurrentTime from 'src/utils/getCurrentTIme'

const SyncContext = createContext<TSyncProps>({
  lastSyncTime: LocalStorageManager.get(config.lastSyncStorageName) ?? '',
  storeLastSyncTime: () => {},
})

const SyncProvider = ({ children }: IProps) => {
  const [lastSyncTime, setLastSyncTime] = useState(
    LocalStorageManager.get(config.lastSyncStorageName) ?? '',
  )

  const storeLastSyncTime = () => {
    LocalStorageManager.set(config.lastSyncStorageName, getCurrentTime())
    setLastSyncTime(getCurrentTime())
  }

  return (
    <SyncContext.Provider
      value={{
        lastSyncTime,
        storeLastSyncTime,
      }}
    >
      {children}
    </SyncContext.Provider>
  )
}

export { SyncProvider, SyncContext }
