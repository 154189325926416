import { styled } from '@mui/material/styles'
import { DatePicker as MaterialDatePicker } from '@mui/x-date-pickers'

export const Wrapper = styled('div')<{ hideClearButton: boolean }>(
  ({ hideClearButton }) => ({
    position: 'relative',
    display: 'flex',
    paddingLeft: '16px',
    '@media (min-width: 1440px)': {
      paddingLeft: '20px',
    },

    ...(hideClearButton
      ? {
          input: {
            marginRight: '27px',
          },
        }
      : {}),

    '.MuiTouchRipple-root': {
      display: 'none',
    },
  }),
)

export const InvisibleClickableArea = styled('span')<{
  hideClearButton: boolean
}>(({ hideClearButton }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: hideClearButton ? 'calc(100% - 34px)' : 'calc(100% - 62px)',
  height: '100%',
  zIndex: 1,
}))

export const DatePicker = styled(MaterialDatePicker, {
  shouldForwardProp: (prop: string) => prop !== 'isRed',
})<{ isRed: boolean }>(({ theme, isRed }) => ({
  width: '100%',

  '.MuiInputBase-input': {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '0.834rem',
    color: isRed ? theme.errorRed : theme.mainTextColor,
    padding: '9px',
    paddingRight: 0,
    lineSize: '0.9rem',
    display: 'inline-block',
    minWidth: '3rem',
  },

  'input::placeholder': {
    opacity: 1,
  },
}))
