import { styled } from '@mui/material/styles'

export const LayoutWrapper = styled('div')(({ theme }) => ({
  height: '100vh',
  backgroundColor: theme.mainBackground,
  color: theme.mainTextColor,
  overflowY: 'auto',

  '::-webkit-scrollbar': {
    width: 5,
    backgroundColor: theme.scrollbarBackground,
  },

  '::-webkit-scrollbar-thumb': {
    background: theme.scrollbarTrack,
    borderBadius: 10,
    cursor: 'pointer',
  },
}))

export const Content = styled('div')({
  display: 'flex',
  zIndex: 1,
})

export const ChildrenWrapper = styled('div')({
  margin: '0 auto',
  padding: '25px 0',
  width: '90vw',
  maxWidth: '100%',
})

export const LoaderWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 10,
  color: theme.mainTextColor,
  backgroundColor: theme.mainBackground,
}))

export const IconWrapper = styled('div')(() => ({
  borderRadius: 4,
  width: 24,
  height: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& img': {
    transform: 'scale(1.4)',
  },
}))
