import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const useURL = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const readURL = useCallback((): Record<string, unknown> => {
    const search = location.search.substring(1)
    const decoded = decodeURIComponent(search)
    let parsed = null

    try {
      parsed = JSON.parse(decoded)
    } catch (e) {
      parsed = {}
    }

    return parsed
  }, [location.search])

  const URL = useMemo((): Record<string, unknown> => readURL(), [readURL])

  const updateURL = useCallback(
    (payload: Record<string, unknown>) => {
      const updated = Object.assign(URL, payload)
      const json = JSON.stringify(updated)
      const encoded = encodeURIComponent(json)

      navigate(
        {
          search: encoded,
        },
        {
          state: location.state,
          replace: true,
        },
      )
    },
    [location.state, navigate, URL],
  )

  return {
    readURL,
    updateURL,
    URL,
  }
}

export default useURL
