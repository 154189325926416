import { styled } from '@mui/material/styles'
import { IColorSquare } from './types'

export const Square = styled('div')<IColorSquare>(({ color }) => ({
  backgroundColor: color,
  minWidth: '12px',
  width: '12px',
  minHeight: '12px',
  height: '12px',
  borderRadius: '4px',
}))
