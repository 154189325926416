import { useState, useEffect } from 'react'
import { getDateFormat } from 'src/consts/dateConsts'

type TNullableDate = Date | null

interface IUseDatePickerStateParams {
  defaultValue: TNullableDate
  onChange: (val: TNullableDate) => void
}

interface IUseDatePickerState {
  value: TNullableDate
  format: 'MMM dd' | '--'

  updateDueDate: (value: TNullableDate) => void
  clearDueDate: () => void
}

export function useDatePickerState({
  defaultValue,
  onChange,
}: IUseDatePickerStateParams): IUseDatePickerState {
  const [value, setValue] = useState<IUseDatePickerState['value']>(defaultValue)
  const [format, setFormat] = useState<IUseDatePickerState['format']>(
    getDateFormat(defaultValue),
  )

  useEffect(() => {
    setFormat(value ? 'MMM dd' : '--')
  }, [value])

  function updateDueDate(value: Date | null) {
    setValue(value)
    onChange(value)
  }

  function clearDueDate() {
    setValue(null)
    onChange(null)
  }

  return {
    value,
    format,
    clearDueDate,
    updateDueDate,
  }
}
