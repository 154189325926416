import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from 'src/hooks/useUserContext'

import Settings from 'src/components/templates/Settings'

const SettingsPage = () => {
  const { user } = useUserContext()

  const navigate = useNavigate()

  useEffect(() => {
    if (!user.id) {
      navigate('/signin', { replace: true })
    }
  }, [user, navigate])

  return <Settings />
}

export default SettingsPage
