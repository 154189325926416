import { styled } from '@mui/material/styles'

import {
  TableContainer as MaterialTableContainer,
  Container as MaterialContainer,
  Divider as MaterialDivider,
} from '@mui/material'

export const Container = styled(MaterialContainer)(() => ({
  '&.MuiContainer-root': {
    width: '100%',
    maxWidth: '100%',
  },
}))

export const TableContainer = styled(MaterialTableContainer)(({ theme }) => ({
  margin: '1px auto',
  marginBottom: '32px',
  boxShadow: 'none',
  background: 'transparent',
  color: theme.mainTextColor,
  overflow: 'visible',
})) as typeof MaterialTableContainer

export const TableTitle = styled('div')<{ withRedCount?: boolean }>(
  ({ theme, withRedCount }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: '6px',
    marginBottom: '20px',

    '& > h3': {
      padding: '3px 6px',
      background: withRedCount ? theme.red : theme.green,
      color: theme.white,
      borderRadius: '8px',
    },
  }),
)

export const Divider = styled(MaterialDivider)(({ theme }) => ({
  borderColor: theme.mainBorderColor,
}))
