import { TFormattedTasks, TPriority } from 'src/pages/Dashboard/types'

import * as S from './styles'
import { PriorityPicker, DatePicker, TaskName, Workspace } from './fields'

export interface ITasksTableBody {
  tasks: TFormattedTasks
  priorities: TPriority[]
  areProjectsVisible?: boolean
}

const TasksTableBody = ({
  tasks,
  priorities,
  areProjectsVisible,
}: ITasksTableBody) => {
  return (
    <>
      {tasks.map((task) => (
        <S.TableRow key={task.gid}>
          <Workspace task={task} areProjectsVisible={areProjectsVisible} />

          <TaskName task={task} />

          <PriorityPicker task={task} priorities={priorities} />

          <DatePicker task={task} />
        </S.TableRow>
      ))}
    </>
  )
}

export default TasksTableBody
