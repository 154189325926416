import MaterialButton from '@mui/material/Button'
import { styled } from '@mui/material/styles'

export const StyledButton = styled(MaterialButton)(
  ({ theme, variant, size }) => ({
    width: 'max-content',
    borderRadius: 50,
    boxShadow: `0 5px 25px ${theme.buttonBoxShadowColor}`,
    fontSize: theme.fonts.button,
    lineHeight: '18.5px',
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.typography.fontFamily,
    textTransform: 'none',
    justifyContent: 'space-around',
    color: theme.white,
    backgroundColor: theme.blue,

    '&:hover': {
      backgroundColor: theme.lightBlue,
      boxShadow: `0 5px 25px ${theme.buttonBoxShadowColor}`,
    },
    '&:active': {
      backgroundColor: theme.darkBlue,
      boxShadow: `0 5px 25px ${theme.buttonBoxShadowColor}`,
    },

    ...(variant === 'outlined' && {
      boxShadow: 'none',
      border: 'none',
      backgroundColor: theme.white,
      '&:hover': {
        boxShadow: '0 5px 25px #15B7DF14',
        border: 'none',
      },
      '&:active': {
        backgroundColor: theme.lightGray,
        boxShadow: '0px 5px 25px #15B7DF14',
        border: 'none',
      },
      color: theme.superDarkBlue,
    }),

    height: 37,
    minWidth: 141,

    ...(size === 'medium' && {
      height: 55,
      minWidth: 141,
    }),
    ...(size === 'large' && {
      height: 154,
      minWidth: 454,
      borderRadius: 10,
      fontSize: 23,
    }),
  }),
)
