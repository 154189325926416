import * as S from './styles'
import { FC, useRef } from 'react'
import { getRandomEmptyStateMessage } from './utils'

const EmptyState: FC = () => {
  const message = useRef(getRandomEmptyStateMessage())

  if (message.current)
    return (
      <S.Wrapper>
        <S.Emoji>{message.current.emoji}</S.Emoji>

        <S.MainHeader variant='h3' fontWeight='bold'>
          {message.current.title}
        </S.MainHeader>

        <S.Text variant='body1'>Brak przypisanych do Ciebie zadań</S.Text>
      </S.Wrapper>
    )

  return <></>
}

export default EmptyState
