import * as React from 'react'
import { SvgIcon } from '@mui/material'
import { styled } from '@mui/material/styles'

function createSvgIcon(
  path: React.ReactNode,
  displayName: string,
): typeof SvgIcon {
  // @ts-ignore internal component
  function Component(props, ref) {
    return (
      <SvgIcon
        data-testid={`${displayName}Icon`}
        style={{
          background: 'transparent',
          width: '22px',
          height: '22px',
        }}
        viewBox='0 0 18 20'
        ref={ref}
        {...props}
      >
        {path}
      </SvgIcon>
    )
  }

  if (process.env.NODE_ENV !== 'production') {
    // Need to set `displayName` on the inner component for React.memo.
    // React prior to 16.14 ignores `displayName` on the wrapper.
    Component.displayName = `${displayName}Icon`
  }

  // @ts-ignore internal component
  Component.muiName = SvgIcon.muiName

  // @ts-ignore internal component
  return React.memo(React.forwardRef(Component))
}

const Icon = createSvgIcon(
  <path d='M2 7l7 7l7-7' fill='none' strokeWidth='3' />,
  'ArrowDropDownIcon',
)

export default styled(Icon)(({ theme }) => ({
  stroke: theme.superDarkBlue,
}))
