import { spinningAnimation } from 'src/keyframes/spin'
import { styled } from '@mui/material/styles'

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '8px',
  gap: '2px',
}))

export const LastSync = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  color: theme.red,
  fontSize: theme.fonts.small,
  fontWeight: theme.typography.fontWeightMedium,
  gap: '2px',
}))

export const SyncNow = styled('div')<{ isClickable?: boolean }>(
  ({ theme, isClickable }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    color: theme.lazure,
    fontSize: theme.fonts.small,
    fontWeight: theme.typography.fontWeightMedium,
    gap: '2px',

    ...(!isClickable
      ? {
          cursor: 'pointer',

          '&:hover': {
            opacity: 0.7,
          },
        }
      : {
          cursor: 'default',
        }),
  }),
)

export const SyncText = styled('span')(() => ({
  whiteSpace: 'nowrap',
}))

export const SyncIconWrapper = styled('span')<{ isSynchronizing?: boolean }>(
  ({ isSynchronizing }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    img: isSynchronizing && {
      animation: `${spinningAnimation} infinite linear 1s normal`,
    },
  }),
)
