import { useMutation } from '@apollo/client'
import { useContext, useEffect, useMemo } from 'react'
import { useUserContext } from 'src/hooks/useUserContext'
import { useTasksContext } from 'src/hooks/useTasksContext'
import { useDataSyncResultSnackbar } from './hooks/useDataSyncResultSnackbar'

import { getSyncText } from './utils'
import SyncAlertRenew from 'src/assets/images/sync-renew.svg'
import SyncAlertExclamationIcon from 'src/assets/images/sync-alert-exc.svg'

import * as S from './styles'
import { AsanaSyncDocument } from 'src/__generated__/graphql'
import { SyncContext } from 'src/providers/SyncProvider'
import DataSyncIcon from 'src/components/atoms/DataSyncIcon'

const SyncInfo = () => {
  const { displayDataSyncResultSnackbar } = useDataSyncResultSnackbar()
  const { lastSyncTime, storeLastSyncTime } = useContext(SyncContext)

  const { user, isUserSynchronizing, setIsUserSynchronizing } = useUserContext()
  const { fetchTasks, numberOfUpdateMutationsInQueue } = useTasksContext()

  const variables = useMemo(
    () => ({
      first: 9999,
      userId: user.id,
    }),
    [user],
  )

  const [asanaSync, { loading: asanaSyncLoading }] = useMutation(
    AsanaSyncDocument,
    {
      onError: () => {
        setIsUserSynchronizing(false)
        displayDataSyncResultSnackbar('ERROR')
      },
      onCompleted: async () => {
        try {
          await fetchTasks({ variables })
          displayDataSyncResultSnackbar('SUCCESSFUL')
        } catch {
          displayDataSyncResultSnackbar('ERROR')
        } finally {
          storeLastSyncTime()
          setIsUserSynchronizing(false)
        }
      },
    },
  )

  const handleAsanaSync = () => {
    if (
      !asanaSyncLoading &&
      !isUserSynchronizing &&
      numberOfUpdateMutationsInQueue === 0
    ) {
      setIsUserSynchronizing(true)
      asanaSync()
    }
  }

  useEffect(() => {
    if (asanaSyncLoading) {
      displayDataSyncResultSnackbar('LOADING')
    }
  }, [asanaSyncLoading])

  return (
    <S.Wrapper>
      <S.LastSync>
        <img src={SyncAlertExclamationIcon} alt='sync_date' />
        <S.SyncText>{`Ostatnia synch.: ${lastSyncTime ?? '-'}`}</S.SyncText>
      </S.LastSync>

      <S.SyncNow
        onClick={handleAsanaSync}
        isClickable={
          isUserSynchronizing && numberOfUpdateMutationsInQueue === 0
        }
      >
        <DataSyncIcon
          isSynchronizing={isUserSynchronizing}
          imgProps={{
            alt: 'sync_now',
            src: SyncAlertRenew,
          }}
        />

        <S.SyncText>
          {getSyncText({
            isUserSynchronizing,
            numberOfUpdateMutationsInQueue,
          })}
        </S.SyncText>
      </S.SyncNow>
    </S.Wrapper>
  )
}

export default SyncInfo
