import { styled } from '@mui/material/styles'
import { spinningAnimation } from 'src/keyframes/spin'

export const SyncIconWrapper = styled('span')<{ isSynchronizing?: boolean }>(
  ({ isSynchronizing }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    img: isSynchronizing && {
      animation: `${spinningAnimation} infinite linear 1s normal`,
    },
  }),
)
