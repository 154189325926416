import MenuList from 'src/components/molecules/MenuList'
import { TMenuListProps } from 'src/components/molecules/MenuList/types'

import * as S from './styles'
import SyncInfo from 'src/components/molecules/SyncInfo/SyncInfo'
import LocalStorageManager from 'src/utils/LocalStorageManager'
import config from 'src/config'

interface IProps {
  lists: TMenuListProps[]
}

const Sidebar = ({ lists }: IProps) => {
  const sidebarState = LocalStorageManager.get(config.sidebarStateName)

  const isSidebarOpen = Boolean(
    sidebarState === null ? true : sidebarState === 'true',
  )

  return (
    <S.Sidebar>
      <div>
        {lists.map((list) => (
          <MenuList
            title={list.title}
            buttons={list.buttons}
            key={list.title}
          />
        ))}
      </div>
      {isSidebarOpen && (
        <S.SyncInfoWrapper>
          <SyncInfo />
        </S.SyncInfoWrapper>
      )}
    </S.Sidebar>
  )
}

export default Sidebar
