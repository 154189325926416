import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import React, { FC } from 'react'

import { StyledButton } from './styles'
import CircularProgress from '../CircularProgress'

export interface ButtonProps {
  label: string
  variant?: 'contained' | 'outlined' | undefined
  size?: 'small' | 'medium' | 'large'
  className?: string
  withArrow?: boolean
  onClick: (e: React.MouseEvent) => void
  isLoading?: boolean
}

export const ThemedButton: FC<ButtonProps> = ({
  label,
  className = '',
  variant = 'contained',
  size = 'small',
  withArrow = false,
  onClick,
  isLoading,
  ...props
}) => {
  return (
    <StyledButton
      variant={variant}
      type='submit'
      className={className}
      size={size}
      onClick={onClick}
      disableRipple
      {...props}
    >
      {isLoading ? <CircularProgress size={'24px'} /> : label}
      {withArrow && (
        <ArrowForwardIcon
          sx={{
            fontSize: 18,
            ...(size === 'large' && {
              fontSize: 36,
              border: '2px solid #FFFFFF33',
              borderRadius: '50%',
              padding: '10px',
            }),
          }}
        />
      )}
    </StyledButton>
  )
}
