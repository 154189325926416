import { styled, darken, lighten } from '@mui/material/styles'
import Button from '@mui/material/Button'

export const MenuButton = styled(Button)(({ theme }) => ({
  textAlign: 'start',
  padding: 8,
  color: theme.mainTextColor,
  backgroundColor: 'transparent',
  fontWeight: 400,
  fontSize: theme.fonts.regular,
  lineHeight: 1.2,
  width: '100%',
  alignItems: 'center',
  justifyContent: 'flex-start',
  textTransform: 'unset',
  borderRadius: 8,
  display: 'flex',

  '@media (min-width: 1440px)': {
    fontSize: theme.fonts.medium,
  },

  '& .MuiButton-endIcon': {
    marginLeft: 'auto',
    opacity: 0,
    transition: 'opacity 0.1s ease-in-out',
  },

  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? lighten(theme.sidebarBackground, 0.1)
        : darken(theme.sidebarBackground, 0.05),
    '& .MuiButton-endIcon': {
      opacity: 1,
    },
  },

  '& .MuiTouchRipple-child': {
    backgroundColor: theme.mainTextColor,
  },
  '& .MuiButton-startIcon': {
    marginLeft: 0,
  },
}))

export const EndIconWrapper = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  borderRadius: '50%',
  backgroundColor: theme.sidebarBackground,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const Content = styled('div')(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))
