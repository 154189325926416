const THEME = 'themeMode'
const MAIL_STATISTICS = 'mail.statistics.opt_out'
const MAIL_OVERDUE_TASKS = 'mail.overdue_tasks.opt_out'
const MAIL_THIS_WEEK_TASKS = 'mail.tasks_due_this_week.opt_out'

const settingNames = [
  THEME,
  MAIL_OVERDUE_TASKS,
  MAIL_THIS_WEEK_TASKS,
  MAIL_STATISTICS,
]
export default settingNames
export { MAIL_STATISTICS, MAIL_OVERDUE_TASKS, MAIL_THIS_WEEK_TASKS }
