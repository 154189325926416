import { darken, lighten, styled } from '@mui/material/styles'

export const TokenInput = styled('input')(({ theme }) => ({
  width: '100%',
  padding: '14px 12px',
  backgroundColor: theme.mainBackground,
  borderRadius: theme.borderRadius,
  border: `1px solid ${theme.mainBorderColor}`,
  color: theme.mainTextColor,
  fontSize: '16px',

  '&:focus': {
    outline: 'transparent',
    border: `1px solid ${theme.blue}`,
  },
}))

export const Content = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  color:
    theme.palette.mode === 'dark'
      ? darken(theme.mainTextColor, 0.4)
      : lighten(theme.mainTextColor, 0.2),
}))
