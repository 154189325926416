import useMessage from 'src/hooks/useMessage'
import { SnackbarContext } from 'src/providers/SnackbarProvider'

import DataSyncIcon from 'src/components/atoms/DataSyncIcon'
import SyncErrorIcon from 'src/assets/images/sync-renew-error.svg'

type TDataSyncResult = 'LOADING' | 'SUCCESSFUL' | 'ERROR'

interface IUseDataSyncResultSnackbar {
  displayDataSyncResultSnackbar: (result: TDataSyncResult) => void
}

export function useDataSyncResultSnackbar(): IUseDataSyncResultSnackbar {
  const { setSnackbarMessage } = useMessage(SnackbarContext)

  const displayDataSyncResultSnackbar: IUseDataSyncResultSnackbar['displayDataSyncResultSnackbar'] =
    function (result) {
      switch (result) {
        case 'LOADING':
          return setSnackbarMessage(
            'Synchronizacja...',
            {},
            <DataSyncIcon isSynchronizing />,
          )

        case 'SUCCESSFUL':
          return setSnackbarMessage('Zsynchronizowano z Twoją Asaną!')

        case 'ERROR':
          return setSnackbarMessage(
            'Błąd synchronizacji',
            {
              isError: true,
            },
            <img src={SyncErrorIcon} alt='sync_error' />,
          )
      }
    }

  return {
    displayDataSyncResultSnackbar,
  }
}
