import { ReactNode, createContext, useState } from 'react'

import Snackbar from 'src/components/organisms/Snackbar/Snackbar'

import { IProps, TSnackbarProps } from './types'
import type { TAdditionalProps } from '@/components/organisms/Snackbar/types'

const SnackbarContext = createContext<TSnackbarProps>({
  message: '',
  isSnackbarOpen: false,
  setIsSnackbarOpen: () => {},
  setMessage: () => {},
  setAdditionalProps: () => {},
  setMessageIcon: () => {},
})

const SnackbarProvider = ({ children }: IProps) => {
  const [message, setMessage] = useState('')
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [additionalProps, setAdditionalProps] = useState<TAdditionalProps>({})
  const [messageIcon, setMessageIcon] = useState<ReactNode | null>(null)

  return (
    <SnackbarContext.Provider
      value={{
        isSnackbarOpen,
        message,
        setIsSnackbarOpen,
        setMessage,
        setAdditionalProps,
        setMessageIcon,
      }}
    >
      {children}
      <Snackbar
        snackbarOpen={isSnackbarOpen}
        snackbarMessage={message}
        setSnackbarOpen={setIsSnackbarOpen}
        additionalProps={additionalProps}
        snackbarIcon={messageIcon}
      />
    </SnackbarContext.Provider>
  )
}

export { SnackbarProvider, SnackbarContext }
