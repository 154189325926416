import { addDays, isBefore } from 'date-fns'

/**
 * Checks if a given date is before today.
 *
 * @param {Date | null} dateToBeChecked - The date to be checked. Can be a Date object or null.
 * @returns {boolean} - Returns true if the date is before today, otherwise false.
 */
export function isDateBeforeToday(dateToBeChecked: Date | null): boolean {
  return !!dateToBeChecked && isBefore(dateToBeChecked, addDays(Date.now(), -1))
}
