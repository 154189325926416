import {
  Tooltip as MaterialTooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  TypographyProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC, PropsWithChildren } from 'react'

export const WorkspaceFieldWrapper = styled('div')(() => ({
  height: '39px',
  width: '100%',
  boxSizing: 'border-box',
}))

export const Link = styled('a')(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  height: '100%',
  position: 'relative',
  textDecoration: 'none',
  color: 'inherit',
  flexGrow: 1,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  paddingLeft: '8px',
  '@media (min-width: 1440px)': {
    paddingLeft: '12px',
  },
}))

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MaterialTooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#444',
  },
}))

const TextBase = styled(Typography)(({ theme }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',

  color: theme.palette.mode === 'dark' ? theme.white : theme.darkText,
}))

export const WorkspaceName: FC<PropsWithChildren<TypographyProps>> = ({
  children,
  ...props
}) => {
  return (
    <TextBase variant='h5' {...props}>
      {children}
    </TextBase>
  )
}
