import { useNavigate } from 'react-router-dom'
import ChevronLeft from '@mui/icons-material/ChevronLeft'

import * as S from './styles'

const GoBackButton = () => {
  const navigate = useNavigate()
  const onClick = () => {
    navigate('../')
  }

  return (
    <S.IconButton onClick={onClick} disableRipple>
      <ChevronLeft width={'24px'} />
    </S.IconButton>
  )
}

export default GoBackButton
