import { styled } from '@mui/material/styles'
import MuiTypography from '@mui/material/Typography'

export const Loader = styled('div')<{ isCentered: boolean }>(
  ({ isCentered }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    ...(isCentered && {
      justifyContent: 'center',
      height: '100%',
    }),
  }),
)

export const Typography = styled(MuiTypography)(({ theme }) => ({
  ...theme.typography.h5,
  marginTop: 12,
  color: theme.superDarkBlue,
}))
