import { useDueOnChange } from './hooks'
import { TFormattedTask } from 'src/pages/Dashboard/types'

import type { FC } from 'react'
import DatePicker from 'src/components/atoms/DatePicker'

import FieldBase from '../_FieldBase'

interface ITaskNameFieldProps {
  task: TFormattedTask
}

const TaskNameField: FC<ITaskNameFieldProps> = ({ task }) => {
  const { updateTaskDueOnDate } = useDueOnChange()

  return (
    <FieldBase align='center' fieldType={'DATE_PICKER'}>
      <DatePicker
        defaultValue={task.due_on ? new Date(task.due_on) : null}
        onChange={(value: Date | null) => updateTaskDueOnDate(task.id, value)}
      />
    </FieldBase>
  )
}

export default TaskNameField
