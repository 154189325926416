import { lighten, styled } from '@mui/material/styles'
import MuiIconButton from '@mui/material/IconButton'

export const IconButton = styled(MuiIconButton)<{}>(({ theme }) => ({
  padding: 8,
  borderRadius: '50%',
  color: theme.mediumGray,
  backgroundColor: theme.lightBackground,
  border: `1px solid ${theme.tableHover}`,
  transition: 'opacity 0.1s ease-in-out, background-color 0.1s ease-in-out',
  '&:hover': {
    backgroundColor: lighten(theme.mediumBackground, 0.05),
    opacity: 0.95,
  },
  '&:active': {
    backgroundColor: theme.darkBlue,
  },
}))
