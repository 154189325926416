import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { UpdateAsanaPatDocument } from 'src/__generated__/graphql'
import EmptyLayout from 'src/components/templates/EmptyLayout'
import VerifyToken from 'src/components/templates/VerifyToken'
import { useUserContext } from 'src/hooks/useUserContext'
import { getStringWithCapitalLetter } from 'src/utils/getStringWithCapitalLetter'
import useMessage from 'src/hooks/useMessage'
import { SnackbarContext } from 'src/providers/SnackbarProvider'

const VerifyTokenPage = () => {
  const { user } = useUserContext()
  const { setSnackbarMessage } = useMessage(SnackbarContext)
  const [updateAsanaPat] = useMutation(UpdateAsanaPatDocument)
  const navigate = useNavigate()
  const [token, setToken] = useState(user.asanaPat || '')

  useEffect(() => {
    if (!user.id) {
      navigate('/signin', { replace: true })
    }
  }, [user, navigate])

  const onChangeNewTokenValue = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setToken(event.target.value)
  }

  const updateToken = async () => {
    if (user.id !== null && token.length !== 0) {
      await updateAsanaPat({
        variables: {
          input: {
            id: user.id,
            asana_pat: token,
          },
        },
      })
        .then(() => {
          navigate('/')
        })
        .catch((error) => {
          const errorField: string = 'input.asana_pat'
          const message = getStringWithCapitalLetter(
            error.graphQLErrors[0].extensions.validation[errorField][0],
          )

          setSnackbarMessage(message, { isError: true })
        })
    }
  }

  return (
    <EmptyLayout>
      <VerifyToken
        token={token}
        updateToken={updateToken}
        onChangeNewTokenValue={onChangeNewTokenValue}
      />
    </EmptyLayout>
  )
}

export default VerifyTokenPage
