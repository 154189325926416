import { keyframes } from '@mui/material/styles'

export const spinningAnimation = keyframes({
  from: {
    rotate: '0deg',
  },

  to: {
    rotate: '360deg',
  },
})
