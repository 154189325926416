import { createContext, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useNavigate, useLocation } from 'react-router-dom'

import { GetUserDataDocument } from 'src/__generated__/graphql'

import getToken from 'src/utils/getToken'
import userSettingNames from 'src/consts/userSettingNames'
import { useColorPaletteContext } from 'src/hooks/useColorPaletteContext'

import { IUserProvider, TUser, TContextValues, TSettings } from './types'
import { PaletteMode } from '@mui/material'

const defaultUserValues: TUser = {
  id: null,
  email: '',
  firstName: '',
  lastName: null,
  asanaPat: null,
  avatarLink: null,
  asanaWorkspaces: [],
  favouriteWorkspaces: [],
}

const unauthorizedRoutes = ['/signin']

const UserContext = createContext<TContextValues>({
  user: defaultUserValues,
  isUserSynchronizing: true,
  setIsUserSynchronizing: () => false,
  settings: {},
  setUser: () => {},
  lastFetchDate: null,
  setLastFetchDate: () => {},
})

const UserProvider = ({ children }: IUserProvider) => {
  const [user, setUser] = useState(defaultUserValues)
  const [settings, setSettings] = useState<TSettings>({})
  const [userDataLoaded, setUserDataLoaded] = useState(false)
  const [isUserSynchronizing, setIsUserSynchronizing] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  const [lastFetchDate, setLastFetchDate] = useState<Date | null>(null)

  const { changePaletteMode } = useColorPaletteContext()

  const [getUserData, { data }] = useLazyQuery(GetUserDataDocument, {
    onCompleted: (data) => {
      const fetchedSettings = data.asanaSettings?.data
      const userSettings: TSettings = {}
      userSettingNames.forEach((settingName) => {
        const setting = fetchedSettings?.find((s) => s.name === settingName)
        userSettings[settingName] = {
          id: setting?.id,
          value: setting?.userValue,
        }
      })
      setSettings(userSettings)

      changePaletteMode(
        (userSettings.themeMode?.value as PaletteMode) === 'dark'
          ? 'dark'
          : 'light',
      )

      if (data.me?.asanaPat === null || data.me?.asanaPat === '') {
        if (location.pathname !== '/verify-token') {
          navigate('/verify-token')
        }
      }
    },
  })

  useEffect(() => {
    if (userDataLoaded) {
      return
    }

    const token = getToken()
    const isOnUnauthorizedRoute = unauthorizedRoutes.includes(location.pathname)

    if (!token && isOnUnauthorizedRoute) {
      setUserDataLoaded(true)
      return
    }

    if (!token && !isOnUnauthorizedRoute) {
      navigate('/signin')
    }

    if (token && !isOnUnauthorizedRoute) {
      getUserData()
    }
  }, [userDataLoaded, getUserData, location, navigate])

  useEffect(() => {
    if (data) {
      setUser(data.me || defaultUserValues)
      setUserDataLoaded(true)
    }
  }, [data])

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isUserSynchronizing,
        setIsUserSynchronizing,
        settings,
        lastFetchDate,
        setLastFetchDate,
      }}
    >
      {userDataLoaded && children}
    </UserContext.Provider>
  )
}

export { UserProvider, defaultUserValues, UserContext }
