import { styled } from '@mui/material/styles'

export const MenuList = styled('div')(() => ({
  marginTop: 16,
}))

export const MenuListTitle = styled('div')(({ theme }) => ({
  color: theme.mainTextColor,
  textAlign: 'start',
  fontWeight: 500,
  fontSize: 16,
  padding: 10,
}))
