import { FC, ReactElement, MouseEvent, HTMLAttributes } from 'react'

import * as S from './styles'
import Tooltip from '@mui/material/Tooltip'
import { clickableIconsWrapperClassName } from './consts'

interface IClickableIconsProps extends HTMLAttributes<HTMLDivElement> {
  actions: {
    tooltip: string
    icon: ReactElement
    onClick: (e: MouseEvent<HTMLButtonElement>) => void

    onlyWhen?: boolean
  }[]
}

const ClickableIcons: FC<IClickableIconsProps> = ({
  actions,
  ...propsToForward
}) => {
  return (
    <S.AllIconsWrapper
      {...propsToForward}
      className={`${clickableIconsWrapperClassName} ${propsToForward.className}`}
    >
      {actions
        .filter((el) => el.onlyWhen !== false)
        .map((el) => {
          return (
            <Tooltip key={el.tooltip} placement='top' title={el.tooltip}>
              <S.SingleIconWrapper onClick={el.onClick}>
                {el.icon}
              </S.SingleIconWrapper>
            </Tooltip>
          )
        })}
    </S.AllIconsWrapper>
  )
}

export default ClickableIcons
