import { useState, useCallback, FC, PropsWithChildren, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useUserContext } from 'src/hooks/useUserContext'

import _isFunction from 'lodash/isFunction'

import config from 'src/config'
import LocalStorageManager from 'src/utils/LocalStorageManager'

import type { TTask } from 'src/pages/Dashboard/types'
import type { ITaskContext } from './types'

import { TasksDocument } from 'src/__generated__/graphql'
import { TasksContext } from './context'
import { useLocalUpdatesRegister } from './hooks'

const TaskContextProvider: FC<PropsWithChildren> = (props) => {
  const cachedTasks: TTask[] = LocalStorageManager.get(
    config.tasksStorageName,
  ) as TTask[]

  const {
    saveLocalUpdates,
    resolveConflictsInIncomingTask,
    clearSavedLocalUpdates,
  } = useLocalUpdatesRegister()

  const [locallySavedTasks, setLocallySavedTasks] = useState<TTask[]>(
    cachedTasks ?? [],
  )

  const [numberOfUpdateMutationsInQueue, setNumberOfUpdateMutationsInQueue] =
    useState<number>(0)

  const [tasksHaveBeenLoaded, setTasksHaveBeenLoaded] = useState<boolean>(
    cachedTasks !== null,
  )

  const { setLastFetchDate } = useUserContext()

  const [fetchTasks, { loading: tasksAreLoading }] = useLazyQuery(
    TasksDocument,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',

      onCompleted: ({ asanaTasks }) => {
        setLastFetchDate(new Date())
        setTasksHaveBeenLoaded(true)

        if (asanaTasks && asanaTasks.data instanceof Array) {
          // Store received tasks
          setLocallySavedTasks(
            (asanaTasks!.data as TTask[]).map(resolveConflictsInIncomingTask),
          )

          // Empty local changes register
          clearSavedLocalUpdates()
        }
      },
      onError: () => {
        setTasksHaveBeenLoaded(true)
      },
    },
  )

  useEffect(() => {
    LocalStorageManager.set(config.tasksStorageName, locallySavedTasks)
  }, [locallySavedTasks])

  const updateTaskLocally = useCallback<ITaskContext['updateTaskLocally']>(
    (taskIdToUpdate, newValue) => {
      // Update task
      setLocallySavedTasks((currentlyLocallySavedTasks) => {
        return currentlyLocallySavedTasks.map((currentTask) => {
          if (currentTask.id !== taskIdToUpdate) return currentTask

          const updatedProperties = _isFunction(newValue)
            ? newValue(currentTask)
            : newValue

          saveLocalUpdates({
            changes: updatedProperties,
            task: currentTask,
          })

          return {
            ...currentTask,
            ...updatedProperties,
          }
        })
      })
    },
    [setLocallySavedTasks],
  )

  return (
    <TasksContext.Provider
      value={{
        tasksHaveBeenLoaded,
        tasks: locallySavedTasks,
        cachedTasks,
        tasksAreLoading,
        numberOfUpdateMutationsInQueue,

        updateTaskLocally,
        fetchTasks,
        setNumberOfUpdateMutationsInQueue,
      }}
    >
      {props.children}
    </TasksContext.Provider>
  )
}

export default TaskContextProvider
