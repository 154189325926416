export function getSyncText(args: {
  isUserSynchronizing: boolean
  numberOfUpdateMutationsInQueue: number
}): string {
  switch (true) {
    case args.numberOfUpdateMutationsInQueue > 0:
      return 'Trwa aktualizowanie tasków...'

    case args.isUserSynchronizing === true:
      return 'Synchronizacja w trakcie...'

    case args.isUserSynchronizing === false:
      return 'Synchronizuj teraz'
  }

  return '-' as never
}
