import { IconButtonProps } from '@mui/material'
import * as S from './styles'

interface IProps extends IconButtonProps {
  children: React.ReactNode
  onClick?: () => void
}

const IconButton = ({
  children,
  onClick,
  ...remainingPropsToForward
}: IProps) => (
  <S.IconButton onClick={onClick} {...remainingPropsToForward}>
    {children}
  </S.IconButton>
)

export default IconButton
