import React, { useState } from 'react'
import { ThemeProvider } from '@mui/material/styles'

import { darkMode, lightMode } from './palette-mode'

import { ColorPaletteContext } from './context'

import './index.css'
import type { PaletteMode } from '@mui/material'

export interface IAppThemeProviderProps {
  children?: React.ReactNode
}

export const AppThemeProvider: React.FC<IAppThemeProviderProps> = ({
  children,
}) => {
  const [colorMode, setColorMode] = useState<PaletteMode>('light')

  return (
    <ColorPaletteContext.Provider
      value={{
        paletteModeInUse: colorMode,
        changePaletteMode: setColorMode,
      }}
    >
      <ThemeProvider theme={colorMode === 'dark' ? darkMode : lightMode}>
        {children}
      </ThemeProvider>
    </ColorPaletteContext.Provider>
  )
}
