import { styled } from '@mui/material/styles'

export const Link = styled('a')(() => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  textDecoration: 'none',
  color: 'inherit',
  height: '39px',
  overflow: 'hidden',
  width: '100%',
  paddingLeft: '8px',
  '@media (min-width: 1440px)': {
    paddingLeft: '12px',
  },
}))

export const TaskNameWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  cursor: 'default',
  justifyContent: 'space-between',
}))

export const TaskName = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  height: '39px',
  flexGrow: 1,
}))

export const ParentTaskNameWrapper = styled('span')(() => ({
  color: 'gray',
  margin: '0 2px',
  fontWeight: 'bolder',

  '& span': {
    fontWeight: 'lighter',
  },

  'span.arrow-icon': {
    marginRight: '6px',
  },
}))
