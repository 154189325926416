import { StyledTextLink } from './styles'

interface IProps {
  children: React.ReactNode
  href: string
  target: string
}

const TextLink = ({ children, href, target }: IProps) => (
  <StyledTextLink href={href} target={target}>
    {children}
  </StyledTextLink>
)

export default TextLink
