import CircularProgress from 'src/components/atoms/CircularProgress/CircularProgress'

import * as S from './styles'
import { ILoaderProps } from './types'

const Loader = ({ isCentered = false }: ILoaderProps) => (
  <S.Loader isCentered={isCentered}>
    <CircularProgress />
    <S.Typography>Wczytywanie danych</S.Typography>
  </S.Loader>
)

export default Loader
