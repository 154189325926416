import { TTask } from '@/pages/Dashboard/types'
import { useCallback, useRef } from 'react'
import _get from 'lodash/get'

import type { IArchieveProperty, ILocalUpdatesArchieve } from '../types'

interface IUseLocalUpdatesRegister {
  saveLocalUpdates: (args: {
    task: TTask
    changes: Partial<Exclude<TTask, 'id'>>
  }) => void

  resolveConflictsInIncomingTask: (incomingTask: TTask) => TTask

  clearSavedLocalUpdates: () => void
}

/**
 * This hook manages local updates to tasks during data synchronization processes.
 * It helps prevent conflicts that may arise when new changes occur before the
 * previous sync's response is received, ensuring that the most recent updates are
 * retained after syncing.
 */
export function useLocalUpdatesRegister(): IUseLocalUpdatesRegister {
  const localUpdatesRegister = useRef<ILocalUpdatesArchieve>(new Map())

  const saveLocalUpdates = useCallback<
    IUseLocalUpdatesRegister['saveLocalUpdates']
  >(({ changes, task }) => {
    // Retrieve the current register for the task or initialize it if not present
    const currentRegisterForThisTask =
      localUpdatesRegister.current.get(task.id) ?? {}

    // Update the register with new changes, storing the 'before' and 'after' states for each changed property
    localUpdatesRegister.current.set(
      task.id,

      Object.assign(
        currentRegisterForThisTask,

        ...(Object.keys(changes) as (keyof TTask)[]).map((key) => {
          return {
            [key]: {
              after: changes[key],
              // Retrieve the 'before' value from the register if it exists, otherwise use the current task value
              before: _get(
                currentRegisterForThisTask,
                `${key}.before`,
                task[key],
              ),
            } as IArchieveProperty<TTask[typeof key]>,
          }
        }),
      ),
    )
  }, [])

  const resolveConflictsInIncomingTask = useCallback<
    IUseLocalUpdatesRegister['resolveConflictsInIncomingTask']
  >((incomingTask) => {
    if (localUpdatesRegister.current.has(incomingTask.id)) {
      Object.entries(
        localUpdatesRegister.current.get(incomingTask.id) as {}, // Object holding previous and current state of the task properties
      ).forEach(([_prop, _savedValue]) => {
        const prop = _prop as keyof TTask
        const savedValue = _savedValue as IArchieveProperty<TTask[typeof prop]>
        // Compare the incoming task's property with the saved 'before' state to determine if an update is needed
        if (
          JSON.stringify(incomingTask[prop]) ===
          JSON.stringify(savedValue.before)
        ) {
          incomingTask[prop] = savedValue.after as any
        }
      })
    }

    return incomingTask
  }, [])

  return {
    saveLocalUpdates,
    resolveConflictsInIncomingTask,
    clearSavedLocalUpdates: () => localUpdatesRegister.current.clear(), // Clear all stored updates when needed
  }
}
