import { styled } from '@mui/material/styles'

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  maxWidth: 600,
  padding: 24,
  gap: 24,
  borderRadius: 8,
  marginBottom: 32,
  backgroundColor: theme.tableHover,
  border: `1px solid ${theme.mainBorderColor}`,
  textAlign: 'left',
}))

export const Center = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
}))

export const TokenInputWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 8,
}))
