class LocalStorageManager {
  set = (name: string, value: object | string | number | undefined) => {
    if (!value) {
      return
    }

    localStorage.setItem(name, JSON.stringify(value))
  }

  get = (name: string) => {
    const value = localStorage.getItem(name)
    if (!value) {
      return null
    }

    return JSON.parse(value)
  }

  clear = (name: string) => {
    localStorage.removeItem(name)
  }
}

export default new LocalStorageManager()
