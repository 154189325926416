export const asanaDateFormat: string = 'yyyy-MM-dd'
export const displayDateFormat: 'MMM dd' = 'MMM dd'

/**
 * DataPicker component by default returns the date's format when date value is not present, therefore we might
 * alter what is being displayed as so-called placeholder by modifying date format
 *
 * @param dataPickerValue the value of DataPicker component
 * @returns Either a proper date format when value is not null or default placeholder otherwise
 */
export function getDateFormat(dataPickerValue: Date | null): 'MMM dd' | '--' {
  return dataPickerValue !== null ? displayDateFormat : '--'
}
