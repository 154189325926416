import { Toolbar, Avatar } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from 'src/hooks/useUserContext'
import _get from 'lodash/get'

import Logo from 'src/components/molecules/Logo/Logo'
import IconButton from 'src/components/atoms/IconButton'
import MenuIcon from '@mui/icons-material/Menu'

import * as S from './styles'
import SyncInfo from 'src/components/molecules/SyncInfo/SyncInfo'
import config from 'src/config'
import LocalStorageManager from 'src/utils/LocalStorageManager'

interface IProps {
  isMenuButton?: boolean
  onMenuClick?: () => void
}

const Header = ({ isMenuButton, onMenuClick }: IProps) => {
  const navigate = useNavigate()
  const { user } = useUserContext()

  const sidebarState = LocalStorageManager.get(config.sidebarStateName)

  const isSidebarOpen = Boolean(
    sidebarState === null ? true : sidebarState === 'true',
  )

  const asanaPat = _get(user, 'asanaPat', '')
  const disableRedirectToSettings: boolean =
    asanaPat === '' || asanaPat === null

  const onUserSettings = () => {
    navigate('/settings')
  }

  return (
    <S.AppBar position='static'>
      <Toolbar>
        <S.LogoContainer>
          {isMenuButton && (
            <IconButton onClick={onMenuClick}>
              <MenuIcon />
            </IconButton>
          )}
          <Logo />
        </S.LogoContainer>
        <S.ProfileContainer>
          <S.SyncContainer>{!isSidebarOpen && <SyncInfo />}</S.SyncContainer>
          <IconButton
            aria-label='current user settings'
            onClick={onUserSettings}
            disabled={disableRedirectToSettings}
          >
            <Avatar src={user.avatarLink || undefined} />
          </IconButton>
        </S.ProfileContainer>
      </Toolbar>
    </S.AppBar>
  )
}

export default Header
