import SignInContainer from 'src/components/molecules/SignInContainer'
import { ISignInContainerProps } from 'src/components/molecules/SignInContainer/types'

import { LayoutWrapper } from './styles'

interface ISignInProps extends ISignInContainerProps {}

const SignIn = ({ signIn }: ISignInProps) => {
  return (
    <LayoutWrapper>
      <SignInContainer signIn={signIn} />
    </LayoutWrapper>
  )
}

export default SignIn
